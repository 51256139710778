import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQuery } from '../core/utils/rtk.utils';
import { IAttachment } from '../models';
import { ENDPOINTS } from '../core/constants';

export const templatesApi = createApi({
  reducerPath: 'templatesApi',
  baseQuery: rtkBaseQuery,
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    generate: build.mutation<IAttachment, any>({
      query: (body) => ({
        url: ENDPOINTS.TEMPLATES.CREATE_ONE,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGenerateMutation } = templatesApi;
