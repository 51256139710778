import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { IconDotsHorizontal } from '../../components/icons';
import { StatusButtonApplication } from '../../components/status-button';
import { StatusHiddenApplication } from '../../components/status-hidden/StatusHiddenApplication';
import { SumCurrency } from '../../components/sum-currency';
import { APPLICATION_SCHEDULE_PAYMENT_TYPES_LABELS, ROUTES } from '../../core/constants';
import {
  ApplicationHiddenTypesEnums,
  ApplicationPaymentScheduleTypesEnums,
} from '../../core/enums';
import {
  dateFormat,
  monthlyAndAnnualPercentage,
  separateNumber,
} from '../../core/utils/format.utils';
import { dynamicRoute } from '../../core/utils/routes.utils';
import { IApplication, IColumn } from '../../models';

export const APPLICATIONS_COLUMNS: IColumn[] = [
  {
    dataKey: 'id',
    label: 'ID',
    sortable: true,
    renderItem: ({ id }: IApplication) => (
      <Typography
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.APPLICATIONS.DETAILS.ROOT, { id })}
        variant="body2"
        color="primary.main"
      >
        {id}
      </Typography>
    ),
  },
  {
    dataKey: 'merchant',
    label: 'Мерчант',
    renderItem: ({
      client_arrive_at,
      merchant,
      partner_user_name,
      partner_shop_name,
    }: IApplication) => (
      <Stack gap={1}>
        <Typography
          component={Link}
          to={dynamicRoute(ROUTES.CABINET.MERCHANTS.DETAILS.ROOT, { id: merchant.id })}
          variant="body2"
        >
          {get(merchant, 'company_name', '')}
        </Typography>
        {client_arrive_at && (
          <Button
            fullWidth
            size="small"
            variant="contained"
            color="warning"
            sx={{
              lineHeight: 1,
            }}
          >
            Приезд: {dateFormat(client_arrive_at, 'dd.MM.Y, HH:mm')}
          </Button>
        )}

        {partner_user_name ? <div>Username: {partner_user_name}</div> : ''}
        {partner_shop_name ? <div>Shopname: {partner_shop_name}</div> : ''}
      </Stack>
    ),
  },
  {
    dataKey: 'loan_amount_desired',
    label: 'Желаемая сумма (сум)',
    sortable: true,
    renderItem: ({ loan_amount_desired }: IApplication) => (
      <Stack alignItems="flex-end">
        <SumCurrency sum={loan_amount_desired} currency="" />
      </Stack>
    ),
  },
  // {
  //   dataKey: 'loan_term',
  //   label: 'Желаемый срок',
  //   sortable: true,
  //   renderItem: ({ loan_term_desired }: IApplication) => {
  //     return loan_term_desired ? separateNumber(Number(loan_term_desired)) : 0;
  //   },
  // },
  {
    dataKey: 'loan_term',
    label: 'Срок',
    sortable: true,
    renderItem: ({ loan_term, loan_term_desired, grace_period }: IApplication) => (
      <Stack>
        <div>{loan_term ? separateNumber(Number(loan_term)) : loan_term_desired}</div>
        {+grace_period > 0 && (
          <Button
            fullWidth
            size="small"
            variant="contained"
            color="warning"
            sx={{
              lineHeight: 1,
              flexDirection: 'column',
              height: 37,
            }}
          >
            Льгот.пер
            <br />
            {grace_period} мес.
          </Button>
        )}
      </Stack>
    ),
  },
  {
    dataKey: 'loan_amount',
    label: 'Одобренная сумма (сум)',
    sortable: true,
    renderItem: ({ loan_amount, cash_amount }: IApplication) => (
      <Stack gap={0.5} alignItems="flex-end">
        <SumCurrency sum={loan_amount} currency="" />
        {+cash_amount > 0 && (
          <Button
            fullWidth
            size="small"
            variant="contained"
            color="warning"
            sx={{
              lineHeight: 1,
              flexDirection: 'column',
              height: 37,
            }}
          >
            наличными
            <br />
            <SumCurrency sum={cash_amount} currency="" />
          </Button>
        )}
      </Stack>
    ),
  },
  {
    dataKey: 'loan_percentage_monthly',
    label: '% ставка в мес. (год)',
    sortable: true,
    renderItem: ({
      loan_percentage_monthly,
      loan_percentage_annual,
      loan_payment_schedule_type,
    }: IApplication) => (
      <Stack>
        <div>{monthlyAndAnnualPercentage(loan_percentage_monthly, loan_percentage_annual)}</div>
        {loan_payment_schedule_type &&
          loan_payment_schedule_type !== ApplicationPaymentScheduleTypesEnums.ANNUITY && (
            <Button
              fullWidth
              size="small"
              variant="contained"
              color="warning"
              sx={{
                lineHeight: 1,
              }}
            >
              {APPLICATION_SCHEDULE_PAYMENT_TYPES_LABELS[loan_payment_schedule_type]}
            </Button>
          )}
      </Stack>
    ),
  },
  // {
  //   dataKey: 'loan_security',
  //   label: 'Обеспечение',
  //   sortable: true,
  //   renderItem: ({ loan_security }: IApplication) => loan_security,
  // },
  {
    dataKey: 'updated_at',
    label: 'Дата',
    sortable: true,
    width: 150,
    renderItem: ({ created_at, updated_at }: IApplication) => (
      <Stack alignItems="flex-end" whiteSpace="nowrap">
        <div>{dateFormat(created_at, 'dd LLL yy / HH:mm')}</div>
        <div>{dateFormat(updated_at, 'dd MMM yy / HH:mm')}</div>
      </Stack>
    ),
    // created_at.replace('T', ' ').replace('Z', '') +
    // ' / ' +
    // updated_at.replace('T', ' ').replace('Z', ''),
    // renderItem: ({ updated_at }: IApplication) => dateFormat(updated_at, 'dd.MM.Y HH:ii:ss'),
  },
  {
    dataKey: 'status',
    label: 'Статус',
    sortable: true,
    renderItem: ({ status, scoring_result, is_hidden }: IApplication) => (
      <Stack gap={0.5}>
        <StatusButtonApplication status={status} />

        {scoring_result !== null && (
          <Box textAlign="center">
            <Typography
              component={Link}
              to={dynamicRoute(ROUTES.CABINET.SCORING.DETAILS, { id: scoring_result.id })}
              color="primary.main"
            >
              Скоринг
              {/*Скоринг {scoring_result.id}*/}
            </Typography>
          </Box>
        )}

        {is_hidden ? <StatusHiddenApplication status={ApplicationHiddenTypesEnums.YES} /> : ''}
      </Stack>
    ),
  },
  // {
  //   dataKey: 'loan',
  //   label: 'Кредит',
  //   sortable: true,
  //   renderItem: ({ loan }: IApplication) => (
  //     (loan && <IconButton
  //       component={Link}
  //       to={dynamicRoute(ROUTES.CABINET.CREDITS.DETAILS, { id: get(loan, 'id', 0) })}
  //       size="small"
  //       color="primary"
  //     >
  //       {get(loan, 'id', 0)}
  //     </IconButton>)
  //   ),
  // },
  {
    dataKey: '',
    label: '',
    width: 60,
    renderItem: ({ id }: IApplication) => (
      <IconButton
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.APPLICATIONS.DETAILS.ROOT, { id })}
        size="small"
        color="primary"
      >
        <IconDotsHorizontal fontSize="small" />
      </IconButton>
    ),
  },
];
