import { Download } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { find } from 'lodash';
import { REPORT_TYPES } from '../../core/constants/report-types.constants';
import { dateFormat } from '../../core/utils/format.utils';
import { IColumn } from '../../models';

export const REPORTS_COLUMNS: IColumn[] = [
  {
    dataKey: 'id',
    label: 'ID',
    // sortable: true,
    renderItem: (row) => (
      <Typography variant="body2" color="primary.main">
        {row.id}
      </Typography>
    ),
  },
  {
    dataKey: 'created_at',
    label: 'Дата генерации',
    renderItem: (row) => dateFormat(row.created_at),
  },
  {
    dataKey: 'report_type',
    label: 'Тип отчета',
    renderItem: (row) => find(REPORT_TYPES, (type) => type.value === row.type)?.label,
  },
  {
    dataKey: 'date_from',
    label: 'Дата начала',
    renderItem: (row) => dateFormat(row.date_from),
  },
  {
    dataKey: 'date_to',
    label: 'Дата окончания',
    renderItem: (row) => dateFormat(row.date_to),
  },

  {
    dataKey: '',
    label: '',
    width: 60,
    renderItem: (row) => (
      <Button
        variant="contained"
        size="small"
        startIcon={<Download />}
        download={`report_${row.type}_${dateFormat(row.date_start)}-${dateFormat(row.date_to)}.xls`}
        href={row.attachments}
        sx={{ whiteSpace: 'nowrap' }}
      >
        Скачать отчет
      </Button>
    ),
  },
];
