import { IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { IconDotsHorizontal } from '../../components/icons';
import { StatusButtonApplication } from '../../components/status-button';
import { SumCurrency } from '../../components/sum-currency';
import { ROUTES } from '../../core/constants';
import { monthlyAndAnnualPercentage, separateNumber } from '../../core/utils/format.utils';
import { dynamicRoute } from '../../core/utils/routes.utils';
import { IApplicationItems, IColumn } from '../../models';

export const APPLICATIONS_ITEMS_COLUMNS: IColumn[] = [
  {
    dataKey: 'id',
    label: 'ID',
    renderItem: ({ id }: IApplicationItems) => (
      <Typography
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.APPLICATIONS.DETAILS.ROOT, { id })}
        variant="body2"
        color="primary.main"
      >
        {id}
      </Typography>
    ),
  },
  {
    dataKey: 'loan_amount_desired',
    label: 'Желаемая сумма (сум)',
    width: 150,
    renderItem: ({ loan_amount_desired }: IApplicationItems) => (
      <SumCurrency sum={loan_amount_desired} currency="сум" />
    ),
  },
  {
    dataKey: 'loan_term_desired',
    label: 'Желаемый срок',
    renderItem: ({ loan_term_desired }: IApplicationItems) => {
      return loan_term_desired ? separateNumber(Number(loan_term_desired)) : 0;
    },
  },
  {
    dataKey: 'loan_amount',
    label: 'Одобренная сумма (сум)',
    width: 150,
    renderItem: ({ loan_amount }: IApplicationItems) => (
      <SumCurrency sum={loan_amount} currency="сум" />
    ),
  },
  {
    dataKey: 'loan_term',
    label: 'Срок',
    renderItem: ({ loan_term }: IApplicationItems) => {
      return loan_term ? separateNumber(Number(loan_term)) : 0;
    },
  },
  {
    dataKey: 'loan_goal',
    label: 'Цель кредита',
    renderItem: ({ loan_goal }: IApplicationItems) => loan_goal,
  },
  {
    dataKey: 'loan_percentage_monthly',
    label: 'Процентная ставка в месяц (год)',
    renderItem: ({ loan_percentage_monthly, loan_percentage_annual }: IApplicationItems) =>
      monthlyAndAnnualPercentage(loan_percentage_monthly, loan_percentage_annual),
  },
  {
    dataKey: 'loan_security',
    label: 'Страховка',
    renderItem: ({ loan_security }: IApplicationItems) => loan_security,
  },
  {
    dataKey: 'updated_at',
    label: 'Дата',
    sortable: true,
    width: 150,
    renderItem: ({ created_at, updated_at }: IApplicationItems) =>
      created_at.replace('T', ' ').replace('Z', '') +
      ' / ' +
      updated_at.replace('T', ' ').replace('Z', ''),
    // renderItem: ({ updated_at }: IApplicationItems) => dateFormat(updated_at, 'dd.MM.Y HH:ii:ss'),
  },
  {
    dataKey: 'status',
    label: 'Статус',
    renderItem: ({ status }: IApplicationItems) => <StatusButtonApplication status={status} />,
  },
  {
    dataKey: '',
    label: '',
    width: 60,
    renderItem: ({ id }: IApplicationItems) => (
      <IconButton
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.APPLICATIONS.DETAILS.ROOT, { id })}
        size="small"
        color="primary"
      >
        <IconDotsHorizontal fontSize="small" />
      </IconButton>
    ),
  },
];
