import { ComponentType } from 'react';
import { get } from 'lodash';
import { Box, Button, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface ErrorPageProps {
  errorCode: number;
}

interface ErrorPagesTextProps {
  title: string;
  text: string;
}

const ERROR_CODES_MESSAGES: { [key: number]: ErrorPagesTextProps } = {
  401: {
    title: 'Требуется авторизация',
    text: 'Для продолжения необходимо авторизоваться',
  },
  403: {
    title: 'Доступ запрещён',
    text: 'Доступ запрещён администратором сайта. Вы можете попробовать вернуться назад, или перейти на главную страницу сайта.',
  },
  404: {
    title: 'Страница не найдена',
    text: 'Запрошенная вами страница не найдена. Вы можете попробовать вернуться назад, или перейти на главную страницу сайта.',
  },
  500: {
    title: 'Внутренняя ошибка сервера',
    text: 'Внутренняя ошибка сервера. Попробуйте повторить запрос позже.',
  },
  1000: {
    title: 'Ошибка приложения',
    text: 'Ошибка приложения. Попробуйте повторить запрос позже.',
  },
};

export const ErrorPage: ComponentType<ErrorPageProps> = ({ errorCode }) => {
  const message = get(ERROR_CODES_MESSAGES, errorCode, ERROR_CODES_MESSAGES[1000]);

  return (
    <Container>
      <Box py={5} textAlign="center">
        <Typography variant="h1" color="primary.dark" mb={0.5}>
          {errorCode}
        </Typography>
        <Typography variant="h2" color="primary.dark" mb={2.5}>
          {message.title}
        </Typography>
        <Typography variant="body2" mb={5}>
          {message.text}
        </Typography>
        <Box>
          <Button color="secondary" variant="contained" component={Link} to="/">
            На главную
          </Button>
          {errorCode !== 500 && <Button>Назад</Button>}
        </Box>
      </Box>
    </Container>
  );
};
