import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQuery } from '../core/utils/rtk.utils';
import { IMe } from '../models';
import { ENDPOINTS } from '../core/constants';

export const meApi = createApi({
  reducerPath: 'meApi',
  baseQuery: rtkBaseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    info: build.query<IMe, any>({
      query: (params = {}) => ({
        url: ENDPOINTS.ME.INFO,
        params,
      }),
    }),
  }),
});

export const { useInfoQuery } = meApi;
