import { KeyboardDoubleArrowLeft, PeopleAlt } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { get, map } from 'lodash';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from '../../core/constants';
import { RoleEnums } from '../../core/enums';
import { SIDEBAR_MENU } from '../../core/menus';
import { isRouteActive } from '../../core/utils/routes.utils';
import { useDrawer, useManagers } from '../../hooks';
import { useInfoQuery } from '../../services/MeApi';
import { LogoApp } from '../logo';
import { SidebarMenuItem } from './styled';

export const Sidebar: ComponentType = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { data } = useInfoQuery({});
  const { handleDrawerToggle, isCollapsed } = useDrawer();
  const { isAdmin } = useManagers();

  const role = get(data, 'data.role_code', RoleEnums.GUEST);
  const menu = get(SIDEBAR_MENU, role, SIDEBAR_MENU[RoleEnums.GUEST]);
  const menuItems = map(menu, ({ label, path, icon }, idx) => (
    <SidebarMenuItem
      to={path}
      isActive={isRouteActive(path, pathname, ROUTES.CABINET.ROOT)}
      key={idx}
    >
      {icon && (
        <Box component="span" mr={!isCollapsed ? 1.5 : 0}>
          {icon}
        </Box>
      )}
      {!isCollapsed && t(label)}
    </SidebarMenuItem>
  ));

  return (
    <Box
      component="aside"
      display="flex"
      flexDirection="column"
      flex={!isCollapsed ? '0 0 280px' : '0 0 73px'}
      width={!isCollapsed ? 280 : 73}
      bgcolor="white"
      borderRight="1px solid #e0e0e0"
      className="no-print"
    >
      <Stack minHeight="100vh" position="sticky" top={0}>
        <Stack
          component={Box}
          direction="row"
          alignItems="center"
          justifyContent={!isCollapsed ? 'space-between' : 'center'}
          borderBottom="1px solid #e0e0e0"
          p={1.5}
        >
          {!isCollapsed && (
            <Link to={ROUTES.CABINET.ROOT} style={{ textDecoration: 'none' }}>
              <LogoApp />
            </Link>
          )}
          <IconButton
            onClick={handleDrawerToggle}
            size="small"
            sx={isCollapsed ? { transform: 'rotate(180deg)' } : {}}
          >
            <KeyboardDoubleArrowLeft />
          </IconButton>
        </Stack>
        <Stack p={1.5} spacing={0.5}>
          {menuItems}
        </Stack>

        {isAdmin && (
          <Stack p={1.5} spacing={0.5} mt="auto">
            <SidebarMenuItem
              to={ROUTES.CABINET.USERS}
              isActive={isRouteActive(ROUTES.CABINET.USERS, pathname, ROUTES.CABINET.ROOT)}
            >
              <Box component="span" mr={!isCollapsed ? 1.5 : 0}>
                <PeopleAlt />
              </Box>
              {!isCollapsed && t('Пользователи')}
            </SidebarMenuItem>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
