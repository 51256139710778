import { ComponentType, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLayout } from '../../../hooks';
// import {
//   OverviewAdmin,
//   OverviewLoanOfficer,
//   OverviewUnderwriter,
// } from '../../../components/overview';
import { Box, Card, CardContent, Stack } from '@mui/material';
import BarChart from '../../../components/charts/bar-chart/BarChart';
import ChartWrapper from '../../../components/charts/chart-wrapper/ChartWrapper';
import DonutChart from '../../../components/charts/donut-chart/DonutChart';
import DualBarChart from '../../../components/charts/dual-bar-chart/DualBarChart';
import { useFetchDashboardQuery } from '../../../services/DashboardApi';

export const DashboardPage: ComponentType = () => {
  const { t } = useTranslation();
  const { handleChangeParams } = useLayout();
  // const { data } = useInfoQuery({});
  const title = t('Обзор');
  // const role = get(data, 'data.role_code', RoleEnums.GUEST);

  useEffect(() => {
    handleChangeParams({ title });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data } = useFetchDashboardQuery({});

  if (!data) return null;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box component={Card} display="flex" flexDirection="column" flex={2}>
        <Box component={CardContent} flex={2}>
          <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} gap={6}>
            <Stack gap={6}>
              <ChartWrapper title="Финансовые показатели">
                <BarChart />
              </ChartWrapper>

              <ChartWrapper title="Заявки и конверсия">
                <DualBarChart />
              </ChartWrapper>
            </Stack>
            <Box display="flex" flexDirection={{ xs: 'row', xl: 'column' }} gap={6}>
              {data?.data.applicationsAll && (
                <ChartWrapper title="Все заявки на кредиты">
                  <DonutChart data={data.data.applicationsAll} label="Всего заявок" />
                </ChartWrapper>
              )}

              {data?.data.applicationsLoan && (
                <ChartWrapper title="Выданные кредиты">
                  <DonutChart data={data.data.applicationsLoan} label="Всего кредитов" />
                </ChartWrapper>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
