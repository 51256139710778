export enum DocumentTypesEnums {
  STATEMENT = 'statement', // Заявление
  LOAN_AGREEMENT = 'loan_agreement', // Кредитный договор
  CONCLUSION = 'conclusion', // Заключение андеррайтера
  SCHEDULE = 'schedule', // График погащения

  CERTIFICATE = 'certificate', // Свидетельство о регистрации
  CERTIFICATE_NDS = 'certificate_nds', // Свидетельство о регистрации НДС
  CHARTER = 'charter', // Устав компании
  AGREEMENT_FOUNDERS = 'agreement_founders', // Учредительный договор
  PASSPORT = 'passport', // Паспорт / ID карта директора
  ORDER_DIRECTOR = 'order_director', // Приказ о назначении директора
  PASSPORT_FOUNDERS = 'passport_founders', // Паспорта / ID карты учредителей
  FORM_1 = 'form_1', // Бухгалтерский баланс (форма №1)
  FORM_2 = 'form_2', // Финансовые результаты (форма №2)

  KATM = 'katm', // КАТМ
  INSURANCE = 'insurance', // Страховка
  STATEMENT_SIGNED = 'statement_signed', // Заявление подписанное
  LOAN_AGREEMENT_SIGNED = 'loan_agreement_signed', // Кредитный договор подписанный
  CONCLUSION_COMPLETED = 'conclusion_completed', // Заключение андеррайтера дополненное
  CONCLUSION_LOAN_EXPERT = 'conclusion_loan_expert', // Заключение кредитного эксперта
  BANK_STATEMENT_USED_MONEY = 'bank_statement_used_money', // Выписка банка об использовании кредитных средств
}
