import { IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { IconDotsHorizontal } from '../../components/icons';
import { ROUTES } from '../../core/constants';
import { dynamicRoute } from '../../core/utils/routes.utils';
import { IColumn, IMerchant } from '../../models';

export const MERCHANTS_COLUMNS: IColumn[] = [
  {
    dataKey: 'id',
    label: 'ID',
    renderItem: ({ id }: IMerchant) => (
      <Typography
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.MERCHANTS.DETAILS.ROOT, { id })}
        variant="body2"
        color="primary.main"
      >
        {id}
      </Typography>
    ),
  },
  {
    dataKey: 'company_name',
    label: 'Мерчант',
    sortable: true,
    renderItem: ({ id, company_name }: IMerchant) => (
      <Typography
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.MERCHANTS.DETAILS.ROOT, { id })}
        variant="body2"
        color="primary.main"
      >
        {company_name}
      </Typography>
    ),
  },
  {
    dataKey: 'company_inn',
    label: 'ИНН / ПИНФЛ',
    sortable: true,
    renderItem: ({ company_inn }: IMerchant) => company_inn,
  },
  {
    dataKey: 'director_passport_series',
    label: 'Серия и номер паспорта директора',
    sortable: false,
    renderItem: ({ director_passport_series, director_passport_id }: IMerchant) =>
      director_passport_series + ' ' + director_passport_id,
  },
  {
    dataKey: 'contact_phone',
    label: 'Контактный номер',
    sortable: true,
    renderItem: ({ contact_phone }: IMerchant) => (
      <Typography variant="body2" whiteSpace="nowrap">
        {contact_phone}
      </Typography>
    ),
  },
  /*{
    dataKey: 'status',
    label: 'Статус',
    sortable: true,
    renderItem: ({ status }: IMerchant) => <StatusButtonMerchant status={status} />,
  },*/
  {
    dataKey: '',
    label: '',
    width: 60,
    renderItem: ({ id }: IMerchant) => (
      <IconButton
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.MERCHANTS.DETAILS.ROOT, { id })}
        size="small"
        color="primary"
      >
        <IconDotsHorizontal fontSize="small" />
      </IconButton>
    ),
  },
];
