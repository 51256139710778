import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TableBody,
  TextField,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { get, map } from 'lodash';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { APPLICATION_SCHEDULE_PAYMENT_TYPES_LABELS, ROUTES } from '../../core/constants';
import { ApplicationPaymentScheduleTypesEnums, ApplicationStatusesEnums } from '../../core/enums';
import { dataRow } from '../../core/utils/data.utils';
import { dateToCustomISOString, parseCustomISOString } from '../../core/utils/format.utils';
import { dynamicRoute } from '../../core/utils/routes.utils';
import { useChangeApplicationStatus, useChangeUnderwriterStatus, useManagers } from '../../hooks';
import {
  useFetchOneApplicationQuery,
  usePatchOneApplicationMutation,
} from '../../services/ApplicationsApi';
import { useFetchUsersByRoleQuery } from '../../services/UsersApi';
import { APPLICATIONS_ROWS } from '../../sources/rows';
import { StyledTable, StyledTableCell, StyledTableRow } from '../data-table/styled';
import { SelectUnderwriterStatus } from '../inputs';
import { StatusButtonApplication, StatusButtonUnderwriter } from '../status-button';

interface Props {}

export const ApplicationDetailsInfo: FC<Props> = () => {
  const { t } = useTranslation();
  const params = useParams();
  const id = get(params, 'id', null);
  const { data, refetch } = useFetchOneApplicationQuery(id);

  const status = get(data, 'data.status');
  const underwriterStatus = get(data, 'data.scoring_result.status_underwriter');
  const loan = get(data, 'data.loan', []);
  const scoringId = get(data, 'data.scoring_result.id');

  const { isManager, isAdmin } = useManagers();

  const [comment, setComment] = useState(data.data.comment || '');
  const [cashAmount, setCashAmount] = useState(data.data.cash_amount || 0);
  const [arriveDate, setArriveDate] = useState(data.data.client_arrive_at);

  const { data: users } = useFetchUsersByRoleQuery({ role_code: 'seller' });
  const [patch] = usePatchOneApplicationMutation();

  const { handleUnderwriterOk, handleUnderwriterNok, handleLoanOfficer, handleLoan } =
    useChangeApplicationStatus({
      id,
      refetch,
    });
  const { handleChange: handleChangeUnderwriterStatus } = useChangeUnderwriterStatus({
    scoringId,
    refetch,
  });

  const isUnderwriterAvailable =
    (status === ApplicationStatusesEnums.UNDERWRITER ||
      status === ApplicationStatusesEnums.PRE_SCORING_OK) &&
    isManager;
  const isLoanOfficerAvailable = status === ApplicationStatusesEnums.UNDERWRITER_OK && isManager;
  const isLoanAvailable = status === ApplicationStatusesEnums.LOAN_OFFICER && isManager;
  const isChangeUnderwriterStatusVisible = !!underwriterStatus && isManager;

  const handleSaveValue = (key: string, value: any) => {
    patch({ id: data.data.id, body: { [key]: value } });
  };

  const rows = dataRow(data, [
    ...APPLICATIONS_ROWS,
    ...[
      {
        label: 'Дата и время приезда клиента',
        field: ['client_arrive_at'],
        renderItem: (value: any) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <DateTimePicker
              value={parseCustomISOString(value)}
              onChange={(newDate) => setArriveDate(newDate)}
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
              sx={{ width: 220 }}
            />
            <Button
              size="small"
              onClick={() => handleSaveValue('client_arrive_at', dateToCustomISOString(arriveDate))}
            >
              Сохранить
            </Button>
          </Box>
        ),
      },
      {
        label: 'Комментарий',
        field: ['comment'],
        renderItem: (value: any) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <TextField
              fullWidth
              size="small"
              defaultValue={value}
              onChange={(e) => setComment(e.target.value)}
              sx={{ maxWidth: 400 }}
            />
            <Button size="small" onClick={() => handleSaveValue('comment', comment)}>
              Сохранить
            </Button>
          </Box>
        ),
      },
      {
        label: 'Сумма наличными',
        field: ['cash_amount'],
        renderItem: (value: any) => {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField
                size="small"
                type="number"
                value={cashAmount}
                onChange={(e) => setCashAmount(e.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">сум</InputAdornment>,
                }}
                sx={{ maxWidth: 220 }}
              />
              <Button onClick={() => handleSaveValue('cash_amount', cashAmount)} size="small">
                Сохранить
              </Button>
            </Box>
          );
        },
      },
      {
        label: 'Льготный период',
        field: ['grace_period'],
        renderItem: (value: any) => {
          return (
            <TextField
              select
              value={value}
              size="small"
              onChange={(e) => handleSaveValue('grace_period', e.target.value)}
            >
              <MenuItem value={0}>Нет</MenuItem>
              <MenuItem value={1}>1 месяц</MenuItem>
              <MenuItem value={2}>2 месяца</MenuItem>
              <MenuItem value={3}>3 месяца</MenuItem>
            </TextField>
          );
        },
      },
      {
        label: 'Тип графика погашения',
        field: ['loan_payment_schedule_type'],
        renderItem: (value: any) => {
          return (
            <TextField
              select
              value={value}
              size="small"
              onChange={(e) => handleSaveValue('loan_payment_schedule_type', e.target.value)}
            >
              {map(ApplicationPaymentScheduleTypesEnums, (status, key) => (
                <MenuItem value={status} key={key}>
                  {t(APPLICATION_SCHEDULE_PAYMENT_TYPES_LABELS[status])}
                </MenuItem>
              ))}
              {/*<MenuItem value="annuity">Аннуитет</MenuItem>*/}
              {/*<MenuItem value="differentiated">Дифференцированный</MenuItem>*/}
            </TextField>
          );
        },
      },
      {
        label: 'Менеджер',
        field: ['seller_id'],
        renderItem: (value: any) => {
          if (!isAdmin) {
            const manager = users?.data.find((user: any) => user.id === parseInt(value));
            return [manager?.first_name, manager?.last_name].join(' ');
          }
          return (
            <TextField
              select
              value={value}
              size="small"
              onChange={(e) => handleSaveValue('seller_id', e.target.value)}
            >
              {users?.data.map((user: any) => (
                <MenuItem key={user.id} value={user.id}>
                  {[user.first_name, user.last_name].join(' ')}
                </MenuItem>
              ))}
            </TextField>
          );
        },
      },
      {
        label: 'Заявка закрытая',
        field: ['is_hidden'],
        renderItem: (value: any) => {
          if (!isAdmin) return value === '1' ? 'Да' : 'Нет';
          return (
            <TextField
              select
              value={value}
              size="small"
              onChange={(e) => handleSaveValue('is_hidden', e.target.value)}
            >
              <MenuItem value={0}>Нет</MenuItem>
              <MenuItem value={1}>Да</MenuItem>
            </TextField>
          );
        },
      },
    ],
  ]);

  return (
    <StyledTable>
      <TableBody>
        {map(rows, (row, idx) => (
          <StyledTableRow key={idx}>
            <StyledTableCell width={250}>{t(row.label)}:</StyledTableCell>
            <StyledTableCell>{row.value}</StyledTableCell>
          </StyledTableRow>
        ))}
        <StyledTableRow>
          <StyledTableCell width={250}>{t('Статус')}:</StyledTableCell>
          <StyledTableCell>
            <Stack direction="row" alignItems="center" spacing={2}>
              <StatusButtonApplication status={status} />
              {isUnderwriterAvailable && (
                <>
                  <Button
                    onClick={handleUnderwriterNok}
                    variant="contained"
                    color="error"
                    size="small"
                  >
                    {t('Отклонить')}
                  </Button>
                  <Button
                    onClick={handleUnderwriterOk}
                    variant="contained"
                    color="success"
                    size="small"
                  >
                    {t('Одобрить')}
                  </Button>
                </>
              )}
              {isLoanOfficerAvailable && isAdmin && (
                <Button
                  onClick={handleLoanOfficer}
                  variant="contained"
                  color="info"
                  size="small"
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {t('Выдать кредит')}
                </Button>
              )}
              {isLoanAvailable && isManager && (
                <Button
                  onClick={handleLoan}
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {t('Кредит выдан')}
                </Button>
              )}
            </Stack>
          </StyledTableCell>
        </StyledTableRow>
        {underwriterStatus && (
          <StyledTableRow>
            <StyledTableCell width={250}>{t('Статус андеррайтера')}:</StyledTableCell>
            <StyledTableCell>
              {isChangeUnderwriterStatusVisible ? (
                <SelectUnderwriterStatus
                  onChange={handleChangeUnderwriterStatus}
                  value={underwriterStatus}
                  size="small"
                  fullWidth
                />
              ) : (
                <StatusButtonUnderwriter status={underwriterStatus} />
              )}
            </StyledTableCell>
          </StyledTableRow>
        )}
        {loan && (
          <StyledTableRow>
            <StyledTableCell width={250}>{t('Кредит')}:</StyledTableCell>
            <StyledTableCell>
              <IconButton
                component={Link}
                to={dynamicRoute(ROUTES.CABINET.CREDITS.DETAILS.ROOT, {
                  id: get(loan, 'id', 0),
                })}
                size="small"
                color="primary"
              >
                {get(loan, 'id', 0)}
              </IconButton>
            </StyledTableCell>
          </StyledTableRow>
        )}
      </TableBody>
    </StyledTable>
  );
};
