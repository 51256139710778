import { ComponentType } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';

interface AnnunciatorProps {
  title?: string;
  text?: string;
}

export const Annunciator: ComponentType<AnnunciatorProps & BoxProps> = ({ title, text }) => {
  return (
    <Box textAlign="center">
      {title && (
        <Typography variant="h5" component="div" mb={0.5} color="text.secondary">
          {title}
        </Typography>
      )}
      {text && (
        <Typography variant="body2" component="p" color="primary.light" mb={0}>
          {text}
        </Typography>
      )}
    </Box>
  );
};
