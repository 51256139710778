import { ComponentType, SyntheticEvent } from 'react';
import { map, get, isEmpty } from 'lodash';
import { Box, IconButton, Stack, Typography, Tabs } from '@mui/material';
import { dynamicRoute } from '../../../core/utils/routes.utils';
import { useLayout } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { UserMenu } from '../../user';
import { IconArrowLeft } from '../../icons';
import { ItemWrapper, LinkTab } from './styled';
import { ROUTES } from '../../../core/constants';

export const Header: ComponentType = () => {
  const navigate = useNavigate();
  const { options } = useLayout();

  const backButton = get(options, 'backButton', false);
  const title = get(options, 'title', '');

  const tabs = get(options, 'tabs', {});
  const menu = get(options, 'tabs.menu', []);
  const activeMenu = get(options, 'tabs.active', 0);
  const pathParams = get(options, 'tabs.pathParams', {});

  const handleChange = (event: SyntheticEvent, idx: number) => {
    const route = get(menu, [idx, 'path'], ROUTES.CABINET.ROOT);
    navigate(dynamicRoute(route, pathParams));
  };

  const renderTabs = () => {
    if (isEmpty(tabs)) {
      return null;
    }

    return (
      <ItemWrapper>
        <Tabs value={activeMenu} onChange={handleChange}>
          {map(menu, (tab, idx) => (
            <LinkTab label={tab.label} href={tab.path} pathParams={pathParams} key={idx} />
          ))}
        </Tabs>
      </ItemWrapper>
    );
  };

  return (
    <Stack
      component={Box}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      minHeight={57}
      px={2}
      bgcolor="white"
      borderBottom="1px solid #e0e0e0"
      className="no-print"
    >
      <Stack component={Box} direction="row" alignItems="center">
        {backButton && (
          <IconButton onClick={() => navigate(-1)} size="small" sx={{ marginRight: 1.5 }}>
            <IconArrowLeft fontSize="small" />
          </IconButton>
        )}
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
        {renderTabs()}
      </Stack>
      <UserMenu />
    </Stack>
  );
};
