import { ComponentType, createElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks';
import { ROUTES } from '../core/constants';

export const authorized =
  () =>
  <T>(Component: ComponentType<T>) => {
    const EnhancedComponent: ComponentType<T> = (props) => {
      const { isAuthorized } = useAuth();

      if (!isAuthorized) {
        return createElement(Navigate, { to: ROUTES.HOME });
      }

      return createElement(Component, props);
    };

    return EnhancedComponent;
  };
