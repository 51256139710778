import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { ENDPOINT_ROOT, BASE_AUTH_TOKEN, ROUTES, BASE_I18NEXT_LANG } from '../constants';

export const rtkBaseHeaders = (headers: Headers) => {
  const token = localStorage.getItem(BASE_AUTH_TOKEN);
  const language = localStorage.getItem(BASE_I18NEXT_LANG) || 'ru';

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  //headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');
  headers.set('api-version', '1');
  headers.set('platform', 'web');
  headers.set('language', language);

  return headers;
};

export const rtkBaseQuery = fetchBaseQuery({
  baseUrl: ENDPOINT_ROOT,
  prepareHeaders: rtkBaseHeaders,
});

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const status = get(action, 'payload.status', null);
    const message = get(action, 'payload.data.message', 'We got a rejected action!');
    if (status === 401) {
      localStorage.removeItem(BASE_AUTH_TOKEN);
      window.location.href = ROUTES.HOME;
    } else if (status > 300 && status < 600) {
      console.warn(message);
    }
  }

  return next(action);
};
