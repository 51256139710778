import { ComponentType, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, map } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { useLayout } from '../../../../hooks';
import { useFetchOneMerchantQuery } from '../../../../services/MerchantsApi';
import { Box, Card, CardContent, Grid, TableBody, TableHead, TableRow } from '@mui/material';
import {
  StyledTable,
  StyledTableCell,
  StyledTableRow,
} from '../../../../components/data-table/styled';
import { LoaderPage } from '../../../../components/loader';
import { MERCHANTS_MENU } from '../../../../core/menus';
import { MERCHANTS_ROWS } from '../../../../sources/rows';

export const MerchantsShopPage: ComponentType = () => {
  const params = useParams();
  const { handleChangeParams } = useLayout();
  const { t } = useTranslation();

  const id = get(params, 'id', null);
  const { data, isFetching, isLoading } = useFetchOneMerchantQuery(id);
  const title = get(data, 'data.company_name');

  const cols = map(MERCHANTS_ROWS, (col) => {
    const field = get(col, 'field', []);
    const value = map(field, (f) => get(data, `data.${f}`, '')).join(' ');

    return {
      label: col.label,
      value,
    };
  });

  useEffect(() => {
    handleChangeParams({
      title,
      backButton: true,
      tabs: {
        menu: MERCHANTS_MENU,
        active: 1,
        pathParams: params,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, params]);

  if (isLoading || isFetching) {
    return <LoaderPage />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box component={Card} display="flex" flexDirection="column" flex={2}>
        <Box component={CardContent} flex={2}>
          <Grid container direction="column" spacing={4}>
            <Grid item xs={4}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <StyledTableCell width={250}>{t('ID')}:</StyledTableCell>
                    <StyledTableCell>{id}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(cols, (col, idx) => (
                    <StyledTableRow key={idx}>
                      <StyledTableCell width={250}>{t(col.label)}:</StyledTableCell>
                      <StyledTableCell>{col.value}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
