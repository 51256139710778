import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from '../core/constants';
import { dynamicRoute } from '../core/utils/routes.utils';
import { rtkBaseQuery } from '../core/utils/rtk.utils';
import { IApplicationsDetails, IApplicationsList } from '../models';

export const applicationsApi = createApi({
  reducerPath: 'applicationsApi',
  baseQuery: rtkBaseQuery,
  keepUnusedDataFor: 0,
  tagTypes: ['Applications'],
  endpoints: (build) => ({
    fetchAllApplications: build.query<IApplicationsList, Record<string, any>>({
      query: (params = {}) => ({
        url: ENDPOINTS.APPLICATIONS.FETCH_ALL,
        params,
      }),
      providesTags: ['Applications'],
    }),
    fetchOneApplication: build.query({
      query: (id) => ({
        url: dynamicRoute(ENDPOINTS.APPLICATIONS.FETCH_ONE, { id }),
      }),
      providesTags: (result, error, id) => [{ type: 'Applications', id }, 'Applications'],
    }),
    patchOneApplication: build.mutation<IApplicationsDetails, Record<string, any>>({
      query: ({ id, body }) => ({
        url: dynamicRoute(ENDPOINTS.APPLICATIONS.PATCH_ONE, { id }),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Applications', id }, 'Applications'],
    }),
    hideApplication: build.mutation<IApplicationsDetails, Record<string, any>>({
      query: ({ id, body }) => ({
        url: dynamicRoute(ENDPOINTS.APPLICATIONS.HIDE, { id }),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Applications', id }, 'Applications'],
    }),
    unhideApplication: build.mutation<IApplicationsDetails, Record<string, any>>({
      query: ({ id, body }) => ({
        url: dynamicRoute(ENDPOINTS.APPLICATIONS.UNHIDE, { id }),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Applications', id }, 'Applications'],
    }),
    copyDocuments: build.mutation({
      query: ({ id }) => ({
        url: dynamicRoute(ENDPOINTS.APPLICATIONS.COPY_DOCS, { id }),
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Applications', id }],
    }),
    exportApplications: build.mutation({
      query: (body) => ({
        url: ENDPOINTS.APPLICATIONS.EXPORT,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useFetchAllApplicationsQuery,
  useFetchOneApplicationQuery,
  usePatchOneApplicationMutation,
  useHideApplicationMutation,
  useUnhideApplicationMutation,
  useCopyDocumentsMutation,
  useExportApplicationsMutation,
} = applicationsApi;
