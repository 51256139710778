import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from '../core/constants';
import { dynamicRoute } from '../core/utils/routes.utils';
import { rtkBaseQuery } from '../core/utils/rtk.utils';
import { IMerchantsDetails, IMerchantsList } from '../models';

export const merchantsApi = createApi({
  reducerPath: 'merchantsApi',
  baseQuery: rtkBaseQuery,
  keepUnusedDataFor: 0,
  tagTypes: ['Merchants'],
  endpoints: (build) => ({
    fetchAllMerchants: build.query<IMerchantsList, Record<string, any>>({
      query: (params = {}) => ({
        url: ENDPOINTS.MERCHANTS.FETCH_ALL,
        params,
      }),
      providesTags: ['Merchants'],
    }),
    fetchBlackListMerchants: build.query<IMerchantsList, Record<string, any>>({
      query: (params = {}) => ({
        url: ENDPOINTS.MERCHANTS.FETCH_BLACK_LIST,
        params,
      }),
      providesTags: ['Merchants'],
    }),
    fetchOneMerchant: build.query({
      query: (id) => ({
        url: dynamicRoute(ENDPOINTS.MERCHANTS.FETCH_ONE, { id }),
      }),
      providesTags: (result, error, id) => [{ type: 'Merchants', id }, 'Merchants'],
    }),
    patchOneMerchant: build.mutation<IMerchantsDetails, Record<string, any>>({
      query: ({ id, body }) => ({
        url: dynamicRoute(ENDPOINTS.MERCHANTS.PATCH_ONE, { id }),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Merchants', id }, 'Merchants'],
    }),
    exportMerchants: build.mutation({
      query: (params) => ({
        url: ENDPOINTS.MERCHANTS.EXPORT,
        method: 'POST',
        body: params,
      }),
    }),
  }),
});

export const {
  useFetchAllMerchantsQuery,
  useFetchBlackListMerchantsQuery,
  useFetchOneMerchantQuery,
  usePatchOneMerchantMutation,
  useExportMerchantsMutation,
} = merchantsApi;
