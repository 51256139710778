import { ChangeEvent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { get } from 'lodash';
import { BASE_PER_PAGE } from '../core/constants';

export const useResources = (useHook: Function) => {
  const [searchParams, setSearchParams]: any = useSearchParams();

  const queryParams = Object.fromEntries(searchParams);
  const { data, ...result } = useHook(queryParams);

  const dataList = get(data, 'data', []);
  const total = get(data, 'total', 0);
  const currentPage = Number(get(queryParams, 'page', 1));
  const perPage = Number(get(queryParams, 'limit', BASE_PER_PAGE));
  const pagesCount = Math.ceil(total / perPage);
  const orderBy = get(queryParams, 'sort', null);
  const orderDirection = get(queryParams, 'order', null);

  const handleChangeParams = (params: Record<string, any>) => {
    setSearchParams(params);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleChangeParam = (field: string, value: any) => {
    const newParams = { ...queryParams, [field]: value };
    if (field !== 'page' || newParams.page === '1' || newParams.page === 1) {
      delete newParams.page;
    }
    handleChangeParams(newParams);
  };

  const handleChangePage = (event: ChangeEvent<unknown>, page: number) => {
    handleChangeParam('page', page);
  };

  const handleChangeLimit = (event: ChangeEvent<HTMLInputElement>) => {
    handleChangeParam('limit', event.target.value);
  };

  const handleChangeSort = (dataKey: string, direction: string | null) => {
    let newParams = { ...queryParams };
    if (direction) {
      newParams = { ...newParams, sort: dataKey, order: direction };
    } else {
      delete newParams.sort;
      delete newParams.order;
    }
    delete newParams.page;
    handleChangeParams(newParams);
  };

  return {
    ...result,
    dataList,
    total,
    pagesCount,
    currentPage,
    perPage,
    orderBy,
    orderDirection,
    handleChangeParam,
    handleChangePage,
    handleChangeLimit,
    handleChangeSort,
  };
};
