import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQuery } from '../core/utils/rtk.utils';
import { IAttachment } from '../models';
import { ENDPOINTS } from '../core/constants';
import { dynamicRoute } from '../core/utils/routes.utils';

export const attachmentsApi = createApi({
  reducerPath: 'attachmentsApi',
  baseQuery: rtkBaseQuery,
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    attach: build.mutation<IAttachment, any>({
      query: (body) => ({
        url: ENDPOINTS.ATTACHMENTS.CREATE_ONE,
        method: 'POST',
        body,
      }),
    }),
    remove: build.mutation<IAttachment, string | number | null>({
      query: (id) => ({
        url: dynamicRoute(ENDPOINTS.ATTACHMENTS.DELETE_ONE, { id }),
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useAttachMutation, useRemoveMutation } = attachmentsApi;
