import { ComponentType, PropsWithChildren, useState } from 'react';
import { SnackbarContext } from '../contexts';
import { Alert, AlertProps, Snackbar } from '@mui/material';

export const SnackbarProvider: ComponentType<PropsWithChildren<unknown>> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState<string>('');
  const [alertProps, setAlertProps] = useState<Record<string, any>>({});

  const showMessage = (message: string, alertProps: AlertProps) => {
    setMessage(message);
    setAlertProps(alertProps);
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      {children}
      <Snackbar
        open={isOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} {...alertProps}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
