import { Add, Delete, Edit } from '@mui/icons-material';
import { Box, Button, Card, CardContent, IconButton, Pagination, Stack } from '@mui/material';
import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { DataTable } from '../../../components/data-table';
import DataTableFilter from '../../../components/data-table-filter/DataTableFilter';
import { AddUserDialog } from '../../../components/dialogs/add-user-dialog';
import { SelectPerPage } from '../../../components/inputs';
import { LoaderPage } from '../../../components/loader';
import { useDialog, useLayout, useManagers, useResources, useSnackbar } from '../../../hooks';
import { useFetchAllUsersQuery, useRemoveOneUserMutation } from '../../../services/UsersApi';
import { USERS_COLUMNS } from '../../../sources/columns/users.columns';

interface Props {}

const UsersPage: FC<Props> = () => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbar();
  const { handleChangeParams } = useLayout();
  const [removeUser] = useRemoveOneUserMutation();
  const { isAdmin } = useManagers();

  const [addUser, closeDialog] = useDialog(AddUserDialog);

  const {
    dataList,
    currentPage,
    perPage,
    pagesCount,
    orderBy,
    orderDirection,
    isFetching,
    isLoading,
    handleChangeLimit,
    handleChangePage,
    handleChangeSort,
  } = useResources(useFetchAllUsersQuery);

  const title = t('Пользователи');

  useEffect(() => {
    handleChangeParams({ title });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAdmin) return null;

  const handleAddClick = () => {
    addUser({ title: 'Добавить пользователя', onClose: closeDialog, showMessage });
  };
  const handleEditClick = (user: Record<string, any>) => {
    addUser({ title: 'Редактирование пользователя', onClose: closeDialog, showMessage, user });
  };

  const handleRemoveUser = (user: Record<string, any>) => {
    if (window.confirm(`Вы уверены что хотите удалить пользователя ${user.first_name}?`)) {
      removeUser(user.id)
        .unwrap()
        .then(() => showMessage('Пользователь успешно удален'))
        .catch((error) => showMessage('Ошибка при удалении пользователя', { severity: 'error' }));
    }
  };

  if (isLoading) {
    return <LoaderPage />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <DataTableFilter noStatus noDates />

      <Box component={Card} display="flex" flexDirection="column" flex={2}>
        <Box component={CardContent} flex={2}>
          <DataTable
            data={dataList}
            isLoading={isFetching}
            columns={[
              ...USERS_COLUMNS,
              {
                dataKey: 'actions',
                label: '',
                renderItem: (user: Record<string, any>) => (
                  <>
                    <IconButton size="small" onClick={() => handleEditClick(user)}>
                      <Edit />
                    </IconButton>
                    {false && (
                      <IconButton size="small" color="error" onClick={() => handleRemoveUser(user)}>
                        <Delete />
                      </IconButton>
                    )}
                  </>
                ),
              },
            ]}
            orderBy={orderBy}
            orderDirection={orderDirection}
            onOrder={handleChangeSort}
          />
          <Box mt={2}>
            <Button startIcon={<Add />} size="small" onClick={handleAddClick}>
              Добавить пользователя
            </Button>
          </Box>
        </Box>
        <Box
          component={Stack}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={1.5}
          p={1.5}
          borderTop="1px solid #E5E9EB"
        >
          <SelectPerPage onChange={handleChangeLimit} value={perPage} />
          <Pagination onChange={handleChangePage} page={currentPage} count={pagesCount} />
        </Box>
      </Box>
    </>
  );
};

export default UsersPage;
