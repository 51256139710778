import { ComponentType, PropsWithChildren } from 'react';
import { StatusButton, StatusButtonProps } from './StatusButton';

export const StatusButtonInfo: ComponentType<PropsWithChildren<StatusButtonProps>> = ({
  children,
  ...props
}) => {
  return (
    <StatusButton color="info" {...props}>
      {children}
    </StatusButton>
  );
};
