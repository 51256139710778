import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import * as yup from 'yup';
import { useLoginMutation } from '../services/AuthApi';
import { IAuth } from '../models';
import { BASE_AUTH_TOKEN, FIELD_LOGIN, FIELD_PASSWORD, ROUTES } from '../core/constants';

export const ValidationSchema = yup.object().shape({
  [FIELD_LOGIN]: yup.string().min(3, 'Минимум 3 символа').required('Обязательное поле').trim(),
  [FIELD_PASSWORD]: yup.string().min(3, 'Минимум 3 символа').required('Обязательное поле').trim(),
});

export const useAuth = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [login, loginResult] = useLoginMutation();

  const token = localStorage.getItem(BASE_AUTH_TOKEN);
  const [isAuthorized, setIsAuthorized] = useState<Boolean>(!!token);

  const handleLogin = (token: string) => {
    localStorage.setItem(BASE_AUTH_TOKEN, token);
    navigate(ROUTES.CABINET.ROOT);
  };

  const handleLogout = () => {
    localStorage.removeItem(BASE_AUTH_TOKEN);
    navigate(ROUTES.HOME);
  };

  const handleSubmit = (values: FormikValues) => {
    login(values)
      .unwrap()
      .then(({ data }: IAuth) => {
        handleLogin(data?.token);
      });
  };

  const hasError = (field: string) => {
    return get(formik, ['errors', field], false) && get(formik, ['touched', field], false);
  };

  const getErrorMessage = (field: string) => {
    if (hasError(field)) {
      return t(get(formik, ['errors', field], 'Ошибка'));
    }

    return '';
  };

  const formik = useFormik({
    initialValues: {
      [FIELD_LOGIN]: '',
      [FIELD_PASSWORD]: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (!!token) {
      setIsAuthorized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...loginResult,
    isAuthorized,
    formik,
    handleLogin,
    handleLogout,
    hasError,
    getErrorMessage,
  };
};
