import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useSnackbar } from './useSnackbar';
import { useDialog } from './useDialog';
import { ConfirmDialog } from '../components/dialogs';
import { usePatchOneApplicationMutation } from '../services/ApplicationsApi';
import { ApplicationStatusesEnums } from '../core/enums';

interface UseChangeApplicationStatusProps {
  id: string | number | null;
  refetch: () => void;
}

export const useChangeApplicationStatus = ({ id, refetch }: UseChangeApplicationStatusProps) => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbar();
  const [openConfirmation] = useDialog(ConfirmDialog);
  const [patch] = usePatchOneApplicationMutation();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    openConfirmation({
      text: t('Вы уверены что хотите поменять статус?'),
      resolve: () => {
        patch({ id, body: { status: e.target.value } })
          .unwrap()
          .then(refetch)
          .catch((e) => {
            const message = get(e, 'data.message');
            showMessage(message, { severity: 'error' });
          });
      },
    });
  };

  const handleStatusChange = (status: ApplicationStatusesEnums, text: string = 'Вы уверены?') => {
    openConfirmation({
      text,
      resolve: () => {
        patch({ id, body: { status } })
          .unwrap()
          .then(refetch)
          .catch((e) => {
            const message = get(e, 'data.message');
            showMessage(message, { severity: 'error' });
          });
      },
    });
  };

  const handleUnderwriterOk = () => {
    handleStatusChange(
      ApplicationStatusesEnums.UNDERWRITER_OK,
      t('Вы уверены что хотите одобрить заявку?')
    );
  };

  const handleUnderwriterNok = () => {
    handleStatusChange(
      ApplicationStatusesEnums.UNDERWRITER_NOK,
      t('Вы уверены что хотите отклонить заявку?')
    );
  };

  const handleLoanOfficer = () => {
    handleStatusChange(
      ApplicationStatusesEnums.LOAN_OFFICER,
      t('Вы уверены что хотите выдать кредит?')
    );
  };

  const handleLoan = () => {
    handleStatusChange(ApplicationStatusesEnums.LOAN, t('Отметить что кредит выдан?'));
  };

  return { handleChange, handleUnderwriterOk, handleUnderwriterNok, handleLoanOfficer, handleLoan };
};
