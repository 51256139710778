export enum ApplicationStatusesEnums {
  NEW = 'new', // Новая заявка
  PRE_SCORING_OK = 'pre_scoring_ok', // Прескоринг одобрен
  PRE_SCORING_NOK = 'pre_scoring_nok', // Прескоринг не одобрен
  UNDERWRITER = 'underwriter', // Андеррайтер рассматривает документы
  UNDERWRITER_OK = 'underwriter_ok', // Кредитный эксперт одобрил
  UNDERWRITER_NOK = 'underwriter_nok', // Кредитный эксперт не одобрил
  LOAN_OFFICER = 'loan_officer', // Кредитный эксперт
  LOAN = 'loan', // Выдали кредит
  PROBLEM = 'problem', // Проблемный кредит
  INSURANCE = 'insurance', // Страховка оплатила
  COMPLETED = 'completed', // Завершен
  CANCELLED = 'cancelled', // Заявка отменена
}
