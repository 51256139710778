import { get, isEmpty } from 'lodash';
import { FormEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { clearEmptyObjectKeys } from '../core/utils/format.utils';

export const useFilter = () => {
  const [searchParams, setSearchParams]: any = useSearchParams();
  const [queryParams, setQueryParams] = useState<Record<string, any>>({});

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);
    setSearchParams(clearEmptyObjectKeys(data));
  };

  const getFieldValue = (field: string) => {
    return get(queryParams, field, '');
  };

  const reset = () => {
    setSearchParams({ date_from: null });
  };

  useEffect(() => {
    const queryParams = Object.fromEntries(searchParams);
    if (!isEmpty(queryParams)) {
      setQueryParams(queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    getFieldValue,
    handleSubmit,
    reset,
  };
};
