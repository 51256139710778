import { Download } from '@mui/icons-material';
import { Box, Button, Card, CardContent, MenuItem, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import { map } from 'lodash';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { APPLICATIONS_STATUSES_LABELS } from '../../core/constants';
import { ApplicationStatusesEnums } from '../../core/enums';
import { formatDateToString } from '../../core/utils/format.utils';
import { useExportApplicationsMutation } from '../../services/ApplicationsApi';
import { useExportLoansMutation } from '../../services/LoansApi';
import { useExportMerchantsMutation } from '../../services/MerchantsApi';

interface Props {
  noStatus?: boolean;
  noDates?: boolean;
  type?: 'applications' | 'merchants' | 'loans';
}
const exportQuery = {
  applications: useExportApplicationsMutation,
  merchants: useExportMerchantsMutation,
  loans: useExportLoansMutation,
};

const DataTableFilter: FC<Props> = ({ noStatus, noDates, type }) => {
  const [searchParams, setSearchParams]: any = useSearchParams();
  const location = useLocation();
  const { t } = useTranslation();

  const [exportData] = type ? exportQuery[type]() : ([() => {}] as any);

  const formik = useFormik({
    initialValues: {
      q: '',
      date_from: null,
      date_to: null,
      statuses: [],
    },
    onSubmit: (values) => {
      const statuses = values.statuses.join(',');
      const newValue = { ...values, statuses };
      const filteredValues = Object.fromEntries(Object.entries(newValue).filter(([_, v]) => !!v));
      setSearchParams(filteredValues);
    },
  });

  const handleUpdateValues = () => {
    const q = searchParams.get('q') || '';
    const date_from = searchParams.get('date_from') || null;
    const date_to = searchParams.get('date_to') || null;
    const statuses = searchParams.get('statuses')?.split(',') || [];
    formik.setValues({ q, date_from, date_to, statuses });
  };

  useEffect(handleUpdateValues, [searchParams, location]);

  const handleChange = (event: any) => {
    const value = event.target.value;
    if (value.includes('')) {
      formik.setFieldValue('statuses', []);
    } else {
      formik.setFieldValue('statuses', value);
    }
  };

  const handleExport = () => {
    exportData(formik.values)
      .unwrap()
      .then((res: any) => {
        const link = document.createElement('a');
        link.href = res.data.url;
        link.setAttribute('download', 'file.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  // useEffect(() => {
  //   console.log('formik.values.statuses', _.includes(formik.values.statuses, ''));
  //   if (_.includes(formik.values.statuses, '')) {
  //     formik.setFieldValue('statuses', []);
  //   }
  // }, [formik]);

  return (
    <Box component={Card} mb={2}>
      <Box component={CardContent}>
        <Stack
          onSubmit={formik.handleSubmit}
          component="form"
          action="/"
          method="GET"
          direction="row"
          alignItems="flex-end"
          spacing={2}
        >
          <Box flex="1 1 auto">
            <TextField {...formik.getFieldProps('q')} label={t('Поиск')} size="small" fullWidth />
          </Box>

          {!noDates && (
            <>
              <DatePicker
                label={t('Дата начала')}
                value={formik.values.date_from ? new Date(formik.values.date_from) : null}
                onChange={(value) =>
                  formik.setFieldValue('date_from', value ? formatDateToString(value) : null)
                }
                slotProps={{
                  textField: {
                    size: 'small',
                  },
                }}
                sx={{ width: 160 }}
              />

              <DatePicker
                label={t('Дата конца')}
                value={formik.values.date_to ? new Date(formik.values.date_to) : null}
                onChange={(value) =>
                  formik.setFieldValue('date_to', value ? formatDateToString(value) : null)
                }
                slotProps={{
                  textField: {
                    size: 'small',
                  },
                }}
                sx={{ width: 160 }}
              />
            </>
          )}

          {!noStatus && (
            <TextField
              {...formik.getFieldProps('statuses')}
              select
              label={t('Статус')}
              size="small"
              SelectProps={{
                multiple: true,
                onChange: handleChange,
              }}
              sx={{ minWidth: 120, maxWidth: 200 }}
            >
              <MenuItem value="">{t('Все')}</MenuItem>
              {map(ApplicationStatusesEnums, (status, key) => (
                <MenuItem value={status} key={key}>
                  {t(APPLICATIONS_STATUSES_LABELS[status])}
                </MenuItem>
              ))}
            </TextField>
          )}
          <Button
            variant="contained"
            color="info"
            size="small"
            onClick={() => {
              setSearchParams([]);
              formik.resetForm();
            }}
          >
            {t('Сбросить фильтр')}
          </Button>
          <Button type="submit" variant="contained" size="small">
            {t('Фильтровать')}
          </Button>

          {type && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<Download />}
              sx={{ ml: 5 }}
              onClick={handleExport}
            >
              Экспорт в Excel
            </Button>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default DataTableFilter;
