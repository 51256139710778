import { Button, Stack, TableBody, TableHead, TableRow } from '@mui/material';
import { get, map } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CreditStatusesEnums } from '../../core/enums';
import { dataRow } from '../../core/utils/data.utils';
import { useChangeCreditStatus, useManagers } from '../../hooks';
import { CREDITS_ROWS } from '../../sources/rows';
import { StyledTable, StyledTableCell, StyledTableRow } from '../data-table/styled';
import { StatusButtonCredit } from '../status-button';

interface Props {
  data: any;
  id: string | null;
  refetch: () => void;
}

export const CreditDetailsInfo: FC<Props> = ({ data, id, refetch }) => {
  const { t } = useTranslation();
  const rows = dataRow(data, CREDITS_ROWS);
  const status = get(data, 'data.status');

  const { isSanjarXalilov, isAdmin } = useManagers();
  const isCloseAvailable =
    status === CreditStatusesEnums.NEW || status === CreditStatusesEnums.IN_PROGRESS;

  const { handleComplete } = useChangeCreditStatus({ id, refetch });

  return (
    <StyledTable>
      <TableHead>
        <TableRow>
          <StyledTableCell width={250}>{t('ID')}:</StyledTableCell>
          <StyledTableCell>{id}</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {map(rows, (row, idx) => (
          <StyledTableRow key={idx}>
            <StyledTableCell width={250}>{t(row.label)}:</StyledTableCell>
            <StyledTableCell>{row.value}</StyledTableCell>
          </StyledTableRow>
        ))}
        <StyledTableRow>
          <StyledTableCell width={250}>{t('Статус')}:</StyledTableCell>
          <StyledTableCell>
            <Stack direction="row" alignItems="center" spacing={2}>
              <StatusButtonCredit status={status} />
              {isCloseAvailable && (isAdmin || isSanjarXalilov) && (
                <Button
                  onClick={handleComplete}
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {t('Завершить')}
                </Button>
              )}
            </Stack>
          </StyledTableCell>
        </StyledTableRow>
      </TableBody>
    </StyledTable>
  );
};
