import { IconLogout } from '../../components/icons';
import { RoleEnums } from '../enums';
import { IMenuItem } from '../../models';
import { SIDEBAR_MENU } from './sidebar.menu';
import { ROUTES } from '../constants';

const LOGOUT_MENU_ITEM: IMenuItem = {
  label: 'Выход',
  path: ROUTES.LOGOUT,
  icon: <IconLogout />,
};

export const USER_MENU: Record<RoleEnums, IMenuItem[]> = {
  [RoleEnums.ADMIN]: [...SIDEBAR_MENU[RoleEnums.ADMIN], LOGOUT_MENU_ITEM],
  [RoleEnums.SELLER]: [...SIDEBAR_MENU[RoleEnums.SELLER], LOGOUT_MENU_ITEM],
  [RoleEnums.UNDERWRITER]: [...SIDEBAR_MENU[RoleEnums.UNDERWRITER], LOGOUT_MENU_ITEM],
  [RoleEnums.LOAN_OFFICER]: [...SIDEBAR_MENU[RoleEnums.LOAN_OFFICER], LOGOUT_MENU_ITEM],
  [RoleEnums.GUEST]: [...SIDEBAR_MENU[RoleEnums.GUEST], LOGOUT_MENU_ITEM],
};
