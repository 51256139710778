import { SumCurrency } from '../../components/sum-currency';
import { separateNumber } from '../../core/utils/format.utils';
import { IRow } from '../../models';

export const APPLICATIONS_ROWS: IRow[] = [
  {
    label: 'Желаемая сумма (сум)',
    field: ['loan_amount_desired'],
    renderItem: (value: any) => <SumCurrency sum={value} currency="сум" />,
  },
  /*{
    label: 'Желаемый срок',
    field: ['loan_term_desired'],
  },*/
  {
    label: 'Одобренная сумма (сум)',
    field: ['loan_amount'],
    renderItem: (value: number | string) => <SumCurrency sum={value} currency="сум" />,
  },
  {
    label: 'Срок',
    field: ['loan_term'],
  },
  {
    label: '% ставка в мес. (год)',
    field: ['loan_percentage_monthly', '% (', 'loan_percentage_annual', '%)']
  },
  /*{
    label: 'Годовая ставка',
    field: ['loan_percentage_annual'],
    renderItem: (value: any) => {
      return (value ? separateNumber(Number(value)) : 0) + '%';
    },
  },
  {
    label: 'Месячная ставка',
    field: ['loan_percentage_monthly'],
    renderItem: (value: any) => {
      return (value ? separateNumber(Number(value)) : 0) + '%';
    },
  },*/
  {
    label: 'Цель кредита',
    field: ['loan_goal'],
  },
  // {
  //   label: 'Тип графика погашения',
  //   field: ['loan_payment_schedule_type'],
  //   renderItem: (value: any) => {
  //     return value ? value : '';
  //   },
  // },
  // {
  //   label: 'Комментарий',
  //   field: ['comment'],
  //   renderItem: (value: any) => {
  //     return value && value.trim() != 'comment' ? value : 'no comment';
  //   },
  // },
  {
    label: 'Дата создания / обновления',
    field: ['created_at', ' / ', 'updated_at'],
    renderItem: (value: any) => {
      return value ? value.replaceAll('T', ' ').replaceAll('Z', '') : '';
    },
  },
  /*{
    label: 'Дата создания',
    field: ['created_at', ',', 'updated_at'],
    renderItem: (value: any) => {
      return value ? value.replace('T', ' ').replace('Z', '') : '';
    },
  },
  {
    label: 'Дата обновления',
    field: ['updated_at'],
    renderItem: (value: any) => {
      return value ? value.replace('T', ' ').replace('Z', '') : '';
    },
  },*/
  {
    label: 'Имя пользователя',
    field: ['partner_user_name'],
    renderItem: (value: any) => {
      return value && value.trim() !== 'partner_user_name' ? value : 'no username';
    },
  },
  {
    label: 'Название магазина',
    field: ['partner_shop_name'],
    renderItem: (value: any) => {
      return value && value.trim() !== 'partner_shop_name' ? value : 'no shopname';
    },
  }
];
