export const ROUTES = {
  HOME: '/',
  LOGOUT: '/logout',
  CABINET: {
    ROOT: '/cabinet',
    APPLICATIONS: {
      ROOT: '/cabinet/applications',
      DETAILS: {
        ROOT: '/cabinet/applications/:id',
        TAB: '/cabinet/applications/:id/:tab',
        INFO: '/cabinet/applications/:id/info',
        FINANCE: '/cabinet/applications/:id/finance',
        DOCS: '/cabinet/applications/:id/docs',
        SCORING: '/cabinet/applications/:id/scoring',
      },
    },
    CREDITS: {
      ROOT: '/cabinet/credits',
      DETAILS: {
        ROOT: '/cabinet/credits/:id',
        TAB: '/cabinet/credits/:id/:tab',
        INFO: '/cabinet/credits/:id/info',
        SCHEDULE: '/cabinet/credits/:id/schedule',
        DOCS: '/cabinet/credits/:id/docs',
        PAYMENTS: '/cabinet/credits/:id/payments/:schedule',
      },
    },
    MERCHANTS: {
      ROOT: '/cabinet/merchants',
      SHOP: '/cabinet/merchants/:id/shop',
      DETAILS: {
        ROOT: '/cabinet/merchants/:id',
        TAB: '/cabinet/merchants/:id/:tab',
        INFO: '/cabinet/merchants/:id/info',
        APPLICATIONS: '/cabinet/merchants/:id/applications',
      },
    },
    MERCHANTS_NEW: {
      ROOT: '/cabinet/merchants-new',
      TAB: '/cabinet/merchants-new/:tab',
      BLACK_LIST: '/cabinet/merchants-new/black-list',
      DETAILS: {
        ROOT: '/cabinet/merchants-new/:id',
        TAB: '/cabinet/merchants-new/:id/:tab',
        INFO: '/cabinet/merchants-new/:id/info',
        APPLICATIONS: '/cabinet/merchants-new/:id/applications',
      },
    },
    SCORING: {
      ROOT: '/cabinet/scoring',
      DETAILS: '/cabinet/scoring/:id',
      SHOP: '/cabinet/scoring/:id/shops',
      PRODUCT: '/cabinet/scoring/:id/products',
      CUSTOMERS: '/cabinet/scoring/:id/customers',
    },
    UNDERWRITER: {
      ROOT: '/cabinet/underwriter',
      DETAILS: '/cabinet/underwriter/:id',
    },
    REPORTS: '/cabinet/reports',
    USERS: '/cabinet/users',
  },
};
