import { Card, Stack } from '@mui/material';
import { get } from 'lodash';
import { ComponentType, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import {
  ApplicationDetailsDocs,
  ApplicationDetailsFinance,
  ApplicationDetailsInfo,
  ApplicationDetailsScoring,
} from '../../../../components/application';
import { DetailTabs } from '../../../../components/detail-tabs';
import { LoaderPage } from '../../../../components/loader';
import { ROUTES } from '../../../../core/constants';
import { useLayout } from '../../../../hooks';
import { useFetchOneApplicationQuery } from '../../../../services/ApplicationsApi';

export const ApplicationsDetailsPage: ComponentType = () => {
  const params = useParams();
  const { handleChangeParams } = useLayout();
  const id = get(params, 'id', null);
  const { data, isFetching, isLoading } = useFetchOneApplicationQuery(id);
  const title = get(data, 'data.merchant.company_name');

  useEffect(() => {
    handleChangeParams({ title, backButton: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, params]);

  if (isLoading || isFetching) {
    return <LoaderPage />;
  }

  const tabs: DetailTabs = {
    info: {
      label: 'Информация',
      route: ROUTES.CABINET.APPLICATIONS.DETAILS.INFO,
      component: <ApplicationDetailsInfo />,
    },
    finance: {
      label: 'Фин. данные',
      route: ROUTES.CABINET.APPLICATIONS.DETAILS.FINANCE,
      component: <ApplicationDetailsFinance />,
    },
    scoring: {
      label: 'Скоринг',
      route: ROUTES.CABINET.APPLICATIONS.DETAILS.SCORING,
      component: <ApplicationDetailsScoring />,
    },
    docs: {
      label: 'Документы',
      route: ROUTES.CABINET.APPLICATIONS.DETAILS.DOCS,
      component: <ApplicationDetailsDocs />,
    },
  };

  return (
    <Stack component={Card}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <DetailTabs tabs={tabs} />
    </Stack>
  );
};
