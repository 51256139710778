import { ComponentType } from 'react';
import { get } from 'lodash';
import { Box, BoxProps, Typography } from '@mui/material';
import { IRequisites } from '../../models';
import { useTranslation } from 'react-i18next';

interface RequisitesProps extends BoxProps {
  data: IRequisites;
}

const TYPO_PROPS: any = {
  variant: 'subtitle2',
  component: 'div',
};

export const Requisites: ComponentType<RequisitesProps> = ({ data, ...props }) => {
  const { t } = useTranslation();

  console.log('data', data);

  const name = get(data, 'name');
  const address = get(data, 'address');
  const phone = get(data, 'phone', '');
  const managerTitle = get(data, 'managerTitle') || t('Директор');
  const manager = get(data, 'manager');
  const accountantTitle = get(data, 'accountantTitle') || t('Главный бухгалтер');
  const accountant = get(data, 'accountant');

  return (
    <Box {...props}>
      {name && (
        <Typography {...TYPO_PROPS} mb={0.5}>
          {name}
        </Typography>
      )}
      {address && (
        <Typography {...TYPO_PROPS} mb={0.5}>
          {t('Адрес')}: {address}
        </Typography>
      )}
      {phone && (
        <Typography {...TYPO_PROPS} mb={0.5}>
          {t('Телефон')}: {phone}
        </Typography>
      )}
      <br />
      {manager && (
        <>
          <Typography {...TYPO_PROPS} mb={0.5}>
            {managerTitle}
          </Typography>
          <Typography {...TYPO_PROPS} fontWeight={700}>
            {manager}
          </Typography>
        </>
      )}
      <br />
      {accountant && (
        <>
          <Typography {...TYPO_PROPS} mb={0.5}>
            {accountantTitle}
          </Typography>
          <Typography {...TYPO_PROPS} fontWeight={700}>
            {accountant}
          </Typography>
        </>
      )}
    </Box>
  );
};
