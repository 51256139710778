import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { get, isEmpty, map } from 'lodash';
import { ComponentType, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  StyledTable,
  StyledTableCell,
  StyledTableRow,
} from '../../../../components/data-table/styled';
import { SelectUnderwriterStatus } from '../../../../components/inputs';
import { LoaderPage } from '../../../../components/loader';
import { StatusButtonUnderwriter } from '../../../../components/status-button';
import { ROUTES } from '../../../../core/constants';
import { RoleEnums } from '../../../../core/enums';
import { numberFormat } from '../../../../core/utils/format.utils';
import { dynamicRoute } from '../../../../core/utils/routes.utils';
import { useChangeUnderwriterStatus, useLayout } from '../../../../hooks';
import { useInfoQuery } from '../../../../services/MeApi';
import { useFetchOneScoringQuery } from '../../../../services/ScoringApi';
import { SCORING_ROWS } from '../../../../sources/rows';

export const ScoringDetailsPage: ComponentType = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { handleChangeParams } = useLayout();
  const { data: userData } = useInfoQuery({});

  const id = get(params, 'id', null);
  const { data, isFetching, isLoading, refetch } = useFetchOneScoringQuery(id);
  const title = get(data, 'data.application.merchant.company_name');
  const underwriterStatus = get(data, 'data.status_underwriter');
  const score = get(data, 'data.score');
  const finances = get(data, 'data.finances');
  const application = get(data, 'data.application');
  const sumOfTerm = get(data, 'data.sum_of_term');

  const rows = map(SCORING_ROWS, (col) => {
    const field = get(col, 'field', []);
    const value = map(field, (f) => get(data, `data.${f}`, '')).join(' ');

    return {
      label: col.label,
      value: col.renderItem ? col.renderItem(value) : value,
    };
  });

  const userRole = get(userData, 'data.role_code');
  const isChangeStatusVisible =
    !!underwriterStatus && (userRole === RoleEnums.ADMIN || userRole === RoleEnums.UNDERWRITER);
  const { handleChange: handleChangeUnderwriterStatus } = useChangeUnderwriterStatus({
    scoringId: id,
    refetch,
  });

  useEffect(() => {
    handleChangeParams({ title, backButton: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, params]);

  if (isLoading || isFetching) {
    return <LoaderPage />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box component={Card} display="flex" flexDirection="column" flex={2}>
        <Box component={CardContent} flex={2}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <StyledTableCell width={250}>{t('ID')}:</StyledTableCell>
                    <StyledTableCell>{id}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledTableCell width={250}>{t('Заявка')}:</StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        component={Link}
                        to={dynamicRoute(ROUTES.CABINET.APPLICATIONS.DETAILS.ROOT, {
                          id: application.id,
                        })}
                        variant="body2"
                        color="primary.main"
                      >
                        ID: {get(application, 'id', 0)}
                      </Typography>
                      <Grid item margin="15px 0 0">
                        <StyledTable>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>{t('Сумма кредита')}</StyledTableCell>
                              <StyledTableCell>{t('Срок кредита')}</StyledTableCell>
                              <StyledTableCell>{t('Ставка месячная (годовая)')}</StyledTableCell>
                              <StyledTableCell>{t('Цель кредита')}</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell>
                                {numberFormat(get(application, 'loan_amount', 0))} {t('сум')}
                              </StyledTableCell>
                              <StyledTableCell>
                                {get(application, 'loan_term', 0)} {t('мес.')}
                              </StyledTableCell>
                              <StyledTableCell>
                                {get(application, 'loan_percentage_monthly', 0)}% (
                                {get(application, 'loan_percentage_annual', 0)}%)
                              </StyledTableCell>
                              <StyledTableCell>{get(application, 'loan_goal', 0)}</StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </StyledTable>
                      </Grid>
                    </StyledTableCell>
                  </TableRow>
                  {map(rows, (row, idx) => (
                    <StyledTableRow key={idx}>
                      <StyledTableCell width={250}>{t(row.label)}:</StyledTableCell>
                      <StyledTableCell>{row.value}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                  {!!underwriterStatus && (
                    <StyledTableRow>
                      <StyledTableCell width={250}>{t('Статус андеррайтера')}:</StyledTableCell>
                      <StyledTableCell>
                        {isChangeStatusVisible ? (
                          <SelectUnderwriterStatus
                            onChange={handleChangeUnderwriterStatus}
                            value={underwriterStatus}
                            size="small"
                            fullWidth
                          />
                        ) : (
                          <StatusButtonUnderwriter status={underwriterStatus} />
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </StyledTable>
            </Grid>
            {!isEmpty(score) && (
              <>
                <Grid item>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>{t('Финансовые риски')}</StyledTableCell>
                        <StyledTableCell>{t('Взвешенный риск')}</StyledTableCell>
                        <StyledTableCell>{t('Показатель')}</StyledTableCell>
                        <StyledTableCell>{t('Score')}</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell>{t('Сумма кредита к выручке')}</StyledTableCell>
                        <StyledTableCell>60%</StyledTableCell>
                        <StyledTableCell>
                          {numberFormat(get(score, 'loan_amount_to_revenue_index'))}
                        </StyledTableCell>
                        <StyledTableCell>
                          {numberFormat(get(score, 'loan_amount_to_revenue_score'))}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell>{t('Срок функционирования бизнеса')}</StyledTableCell>
                        <StyledTableCell>20%</StyledTableCell>
                        <StyledTableCell>
                          {numberFormat(get(score, 'term_of_business_index'))}
                        </StyledTableCell>
                        <StyledTableCell>
                          {numberFormat(get(score, 'term_of_business_score'))}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell>{t('ТМЗ к сумме кредита')}</StyledTableCell>
                        <StyledTableCell>20%</StyledTableCell>
                        <StyledTableCell>
                          {numberFormat(get(score, 'tmz_to_loan_amount_index'))}
                        </StyledTableCell>
                        <StyledTableCell>
                          {numberFormat(get(score, 'tmz_to_loan_amount_score'))}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </StyledTable>
                </Grid>
                <Grid item>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <Box>
                      <Typography variant="h6" color="warning.contrastText">
                        {t('Уровень кредитного риска')}
                      </Typography>
                    </Box>
                    <Stack direction="row" alignItems="center" spacing={4}>
                      <Typography variant="body1" fontWeight={600}>
                        {get(score, 'score_results.code')}
                      </Typography>
                      <Typography variant="body1" fontWeight={600}>
                        {get(score, 'score_results.text')}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </>
            )}
            {!isEmpty(finances) && (
              <>
                <Grid item>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <Box>
                      <Typography variant="h6" color="info.contrastText">
                        {t('Финансовые данные')}
                      </Typography>
                      {!isEmpty(sumOfTerm) && (
                        <Typography variant="h5">
                          {t('За последние')} {get(sumOfTerm, 'term')} {t('месяцев')}{' '}
                          {numberFormat(get(sumOfTerm, 'sum'))} {t('сум')} (20% ={' '}
                          {numberFormat(get(sumOfTerm, 'sum_20', 0))} {t('сум')})
                        </Typography>
                      )}
                    </Box>
                  </Stack>
                </Grid>
                <Grid item>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>{t('Дата')}</StyledTableCell>
                        <StyledTableCell>{t('Валовая выручка')}</StyledTableCell>
                        {/*<StyledTableCell>{t('Возвраты')}</StyledTableCell>*/}
                        {/*<StyledTableCell>{t('Скидки')}</StyledTableCell>*/}
                        <StyledTableCell>{t('Себестоимость')}</StyledTableCell>
                        {/*<StyledTableCell>{t('Валовая прибыль')}</StyledTableCell>*/}
                        <StyledTableCell>{t('Остаток по себестоимости')}</StyledTableCell>
                        {/*<StyledTableCell>{t('Остаток по цене продажи')}</StyledTableCell>*/}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {map(finances, (finance, idx) => (
                        <>
                          <StyledTableRow key={idx}>
                            <StyledTableCell width={150}>{finance.date}</StyledTableCell>
                            <StyledTableCell>
                              {numberFormat(finance.gross_revenue)} {t('сум')}
                            </StyledTableCell>
                            {/*<StyledTableCell>{numberFormat(finance.discount)} {t('сум')}</StyledTableCell>*/}
                            {/*<StyledTableCell>{numberFormat(finance.returns)} {t('сум')}</StyledTableCell>*/}
                            <StyledTableCell>
                              {numberFormat(finance.cost_price)} {t('сум')}
                            </StyledTableCell>
                            {/*<StyledTableCell>{numberFormat(finance.gross_profit)} {t('сум')}</StyledTableCell>*/}
                            <StyledTableCell>
                              {numberFormat(finance.stock_amount_cost)} {t('сум')}
                            </StyledTableCell>
                            {/*<StyledTableCell>{numberFormat(finance.stock_amount_selling)} {t('сум')}</StyledTableCell>*/}
                          </StyledTableRow>
                        </>
                      ))}
                    </TableBody>
                  </StyledTable>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};
