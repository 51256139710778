import { useGenerateMutation } from '../services/TemplatesApi';
import { DocumentTypesEnums, FileTypesEnums } from '../core/enums';

interface UseTemplateProps {
  id: any;
  refetch?: () => void;
}

interface TemplateProps {
  fileType: FileTypesEnums | '';
  docType: DocumentTypesEnums | '';
}

export const useTemplate = ({ id, refetch }: UseTemplateProps) => {
  const [generate] = useGenerateMutation();

  const handleGenerate = ({ fileType, docType }: TemplateProps) => {
    generate({ item_id: id, item_type: fileType, document_type: docType })
      .unwrap()
      .then(() => {
        if (refetch) refetch();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return { handleGenerate };
};
