import { CreditStatusesEnums } from '../enums';

export const CREDITS_STATUSES_LABELS: Record<CreditStatusesEnums, string> = {
  [CreditStatusesEnums.NEW]: 'Новый кредит',
  [CreditStatusesEnums.CANCELED]: 'Отменён',
  [CreditStatusesEnums.IN_PROGRESS]: 'В процессе погашения',
  [CreditStatusesEnums.COMPLETED]: 'Завершен',
  [CreditStatusesEnums.PROBLEM]: 'Проблемный',
  [CreditStatusesEnums.INSURANCE]: 'Оплатила страховка',
};
