import { ComponentType } from 'react';
import { Box, Card } from '@mui/material';
import { Loader } from '../Loader';

export const LoaderPage: ComponentType = () => {
  return (
    <Box
      component={Card}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flex={2}
    >
      <Loader />
    </Box>
  );
};
