import { ComponentType } from 'react';
import { Typography } from '@mui/material';
import { separateNumber } from '../../core/utils/format.utils';
import { useTranslation } from 'react-i18next';

interface SumCurrencyProps {
  sum: number | string;
  currency: string;
}

export const SumCurrency: ComponentType<SumCurrencyProps> = ({ sum, currency }) => {
  const { t } = useTranslation();

  return (
    <Typography variant="body2">
      {sum ? separateNumber(Number(sum)) : 0}
      {currency ? ' ' + t(currency) : ''}
    </Typography>
  );
};
