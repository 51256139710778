import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useSnackbar } from './useSnackbar';
import { useDialog } from './useDialog';
import { ConfirmDialog } from '../components/dialogs';
import { CreditStatusesEnums } from '../core/enums';
import { useCompleteOneLoanMutation, usePatchOneLoanMutation } from '../services/LoansApi';

interface UseChangeCreditStatusProps {
  id: string | number | null;
  refetch: () => void;
}

export const useChangeCreditStatus = ({ id, refetch }: UseChangeCreditStatusProps) => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbar();
  const [openConfirmation] = useDialog(ConfirmDialog);
  const [patch] = usePatchOneLoanMutation();
  const [complete] = useCompleteOneLoanMutation();

  const handleComplete = () => {
    console.log('id', id);
    openConfirmation({
      text: t('Вы уверены что хотите закрыть кредит?'),
      resolve: () => {
        complete(id)
          .unwrap()
          .then(refetch)
          .catch((e) => {
            const message = get(e, 'data.message');
            showMessage(message, { severity: 'error' });
          });
      },
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    openConfirmation({
      text: t('Вы уверены что хотите поменять статус?'),
      resolve: () => {
        patch({ id, body: { status: e.target.value } })
          .unwrap()
          .then(refetch)
          .catch((e) => {
            const message = get(e, 'data.message');
            showMessage(message, { severity: 'error' });
          });
      },
    });
  };

  const handleStatusChange = (status: CreditStatusesEnums, text: string = 'Вы уверены?') => {
    openConfirmation({
      text,
      resolve: () => {
        patch({ id, body: { status } })
          .unwrap()
          .then(refetch)
          .catch((e) => {
            const message = get(e, 'data.message');
            showMessage(message, { severity: 'error' });
          });
      },
    });
  };

  return { handleChange, handleStatusChange, handleComplete };
};
