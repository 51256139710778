import {
  Apps,
  Assessment,
  CreditCard,
  Dashboard,
  LibraryAddCheck,
  Score,
  ShoppingCart,
} from '@mui/icons-material';
import { IMenuItem } from '../../models';
import { ROUTES } from '../constants';
import { RoleEnums } from '../enums';

const SIDEBAR_MENU_ITEM_CABINET = {
  label: 'Обзор',
  path: ROUTES.CABINET.ROOT,
  icon: <Dashboard />,
};

const SIDEBAR_MENU_ITEM_APPLICATIONS = {
  label: 'Заявки на кредиты',
  path: ROUTES.CABINET.APPLICATIONS.ROOT,
  icon: <Apps />,
};

const SIDEBAR_MENU_ITEM_SCORING = {
  label: 'Скоринговые данные',
  path: ROUTES.CABINET.SCORING.ROOT,
  icon: <Score />,
};

const SIDEBAR_MENU_ITEM_UNDERWRITER = {
  label: 'Андеррайтер',
  path: ROUTES.CABINET.UNDERWRITER.ROOT,
  icon: <LibraryAddCheck />,
};

const SIDEBAR_MENU_ITEM_CREDITS = {
  label: 'Кредиты',
  path: ROUTES.CABINET.CREDITS.ROOT,
  icon: <CreditCard />,
};

const SIDEBAR_MENU_ITEM_MERCHANTS = {
  label: 'Мерчанты',
  path: ROUTES.CABINET.MERCHANTS.ROOT,
  icon: <ShoppingCart />,
};

const SIDEBAR_MENU_ITEM_REPORTS = {
  label: 'Отчеты',
  path: ROUTES.CABINET.REPORTS,
  icon: <Assessment />,
};

export const SIDEBAR_MENU: Record<RoleEnums, IMenuItem[]> = {
  [RoleEnums.ADMIN]: [
    SIDEBAR_MENU_ITEM_CABINET,
    SIDEBAR_MENU_ITEM_APPLICATIONS,
    // SIDEBAR_MENU_ITEM_SCORING,
    // SIDEBAR_MENU_ITEM_UNDERWRITER,
    SIDEBAR_MENU_ITEM_CREDITS,
    SIDEBAR_MENU_ITEM_MERCHANTS,
    SIDEBAR_MENU_ITEM_REPORTS,
  ],
  [RoleEnums.SELLER]: [
    SIDEBAR_MENU_ITEM_CABINET,
    SIDEBAR_MENU_ITEM_APPLICATIONS,
    SIDEBAR_MENU_ITEM_CREDITS,
    SIDEBAR_MENU_ITEM_MERCHANTS,
    SIDEBAR_MENU_ITEM_REPORTS,
  ],
  [RoleEnums.UNDERWRITER]: [
    SIDEBAR_MENU_ITEM_CABINET,
    SIDEBAR_MENU_ITEM_APPLICATIONS,
    SIDEBAR_MENU_ITEM_SCORING,
    SIDEBAR_MENU_ITEM_UNDERWRITER,
    SIDEBAR_MENU_ITEM_CREDITS,
    SIDEBAR_MENU_ITEM_MERCHANTS,
  ],
  [RoleEnums.LOAN_OFFICER]: [
    SIDEBAR_MENU_ITEM_CABINET,
    SIDEBAR_MENU_ITEM_APPLICATIONS,
    SIDEBAR_MENU_ITEM_SCORING,
    SIDEBAR_MENU_ITEM_CREDITS,
    SIDEBAR_MENU_ITEM_MERCHANTS,
  ],
  [RoleEnums.GUEST]: [SIDEBAR_MENU_ITEM_CABINET],
};
