import { Box, Card, Stack, Tab, Tabs } from '@mui/material';
import { get, map } from 'lodash';
import { FC, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { dynamicRoute } from '../../core/utils/routes.utils';

interface Props {
  tabs: DetailTabs;
}

export const DetailTabs: FC<Props> = ({ tabs }) => {
  const params = useParams();
  const id = get(params, 'id', null);
  const [tab, setTab] = useState<string>(params.tab || 'info');

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Stack component={Card}>
      <Box px={2} borderBottom={1} borderColor="divider" className="no-print">
        <Tabs value={tab} onChange={handleChangeTab}>
          {map(tabs, (tabItem, key) => (
            <Tab
              label={tabItem.label}
              value={key}
              key={key}
              component={Link}
              to={dynamicRoute(tabItem.route, { id })}
            />
          ))}
        </Tabs>
      </Box>
      <Box p={2}>
        {map(tabs, (tabItem, key) => (
          <Box
            key={key}
            sx={{
              display: tab === key ? 'block' : 'none',
            }}
          >
            {tabItem.component}
          </Box>
        ))}
      </Box>
    </Stack>
  );
};
