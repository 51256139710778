import { Components } from '@mui/material';
import { Palette } from '@mui/material/styles/createPalette';
import { createTheme } from '@mui/system';
import { breakpoints } from './breakpoints';
import { shadows } from './shadows';
import { shape } from './shape';
import { typography } from './typography';

export const themeComponents = (palette: Partial<Palette>): Components => {
  const theme = createTheme({ palette, breakpoints, typography, shadows, shape });

  return {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          minHeight: '100vh',
          height: '100%',
        },
        body: {
          minHeight: '100vh',
          height: '100%',
          overflowY: 'scroll',
          backgroundColor: theme.palette.info.main,
        },
        img: {
          maxWidth: '100%',
        },
        a: {
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
        '#root': {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        },
        'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
          {
            WebkitAppearance: 'none',
            margin: 0,
          },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        sizeSmall: {
          height: 32,
          lineHeight: 1.54,
          padding: theme.spacing(0.75, 1.25),
          '& .MuiButton-startIcon': {
            marginRight: theme.spacing(0.5),
          },
          '& .MuiButton-endIcon': {
            marginLeft: theme.spacing(0.5),
          },
        },
        sizeMedium: {
          height: 48,
          lineHeight: 1.716,
          padding: theme.spacing(1.5, 2.5),
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
        },
        sizeSmall: {
          padding: theme.spacing(0.5),
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2),
          '&:last-child': {
            paddingBottom: theme.spacing(2),
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        root: {
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#eeeff1',
            },
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#eeeff1',
            },
          },
        },
        input: {
          height: 48,
          padding: theme.spacing(0, 2),
        },
        inputSizeSmall: {
          height: 32,
          padding: theme.spacing(0, 1.5),
        },
        notchedOutline: { borderColor: theme.palette.grey[100] },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          height: 48,
          lineHeight: '48px',
          '&.MuiInputBase-inputSizeSmall': {
            height: 32,
            lineHeight: '32px',
            paddingLeft: theme.spacing(1.5),
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          position: 'static',
          transform: 'translate(0, 0) scale(1)',
          width: '100%',
          maxWidth: '100%',
          marginBottom: theme.spacing(0.5),
          color: theme.palette.text.secondary,
          ...typography.body2,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...typography.body2,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          '&.Mui-selected': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  };
};
