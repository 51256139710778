import { styled, Table, TableRow, TableCell } from '@mui/material';

export const StyledTable = styled(Table)(({ theme }) => ({
  borderCollapse: 'collapse',
  borderRadius: theme.shape.borderRadius,
  borderStyle: 'hidden',
  boxShadow: '0 0 0 1px #e0e0e0',
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1.625),
  lineHeight: 1.5,
  border: '1px solid #e0e0e0',
}));
