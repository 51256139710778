import { useAttachMutation, useRemoveMutation } from '../services/AttachmentsApi';
import { DocumentTypesEnums, FileTypesEnums } from '../core/enums';

interface UseAttachmentProps {
  id: any;
  refetch?: () => void;
}

interface AttachProps {
  file: File;
  fileType: FileTypesEnums | '';
  docType: DocumentTypesEnums | '';
}

export const useAttachment = ({ id, refetch }: UseAttachmentProps) => {
  const [attach] = useAttachMutation();
  const [remove] = useRemoveMutation();

  const handleAttach = ({ file, fileType, docType }: AttachProps) => {
    const formData = new FormData();
    formData.append('item_id', String(id));
    formData.append('item_type', fileType);
    formData.append('document_type', docType);
    formData.append('file', file);
    attach(formData)
      .unwrap()
      .then(() => {
        if (refetch) refetch();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleDelete = (id: number) => {
    remove(id)
      .unwrap()
      .then(() => {
        if (refetch) refetch();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return { handleAttach, handleDelete };
};
