import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { UnderwriterStatusesEnums } from '../../../core/enums';
import { UNDERWRITER_STATUSES_LABELS } from '../../../core/constants';

type Props = TextFieldProps & { prompt?: boolean };

export const SelectUnderwriterStatus: ComponentType<Props> = ({ prompt, ...props }) => {
  const { t } = useTranslation();

  return (
    <TextField {...props} select>
      {prompt && <MenuItem value={''}>{t('Все')}</MenuItem>}
      {map(UnderwriterStatusesEnums, (status, key) => (
        <MenuItem value={status} key={key}>
          {t(UNDERWRITER_STATUSES_LABELS[status])}
        </MenuItem>
      ))}
    </TextField>
  );
};
