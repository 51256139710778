import { get } from 'lodash';
import { ComponentType, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { CreditDetailsDocs } from '../../../../components/credit/CreditDetailsDocs';
import { CreditDetailsInfo } from '../../../../components/credit/CreditDetailsInfo';
import { CreditDetailsSchedule } from '../../../../components/credit/CreditDetailsSchedule';
import { DetailTabs } from '../../../../components/detail-tabs';
import { LoaderPage } from '../../../../components/loader';
import { ROUTES } from '../../../../core/constants';
import { useLayout } from '../../../../hooks';
import { useFetchOneLoanQuery } from '../../../../services/LoansApi';

export const CreditsDetailsPage: ComponentType = () => {
  const params = useParams();
  const { handleChangeParams } = useLayout();

  const id = get(params, 'id', null);
  const { data, isFetching, isLoading, refetch } = useFetchOneLoanQuery(id);

  const title = get(data, 'data.application.merchant.company_name');

  const tabs: DetailTabs = {
    info: {
      label: 'Кредитная информация',
      route: ROUTES.CABINET.CREDITS.DETAILS.INFO,
      component: <CreditDetailsInfo data={data} id={id} refetch={refetch} />,
    },
    schedule: {
      label: 'График погашения',
      route: ROUTES.CABINET.CREDITS.DETAILS.SCHEDULE,
      component: <CreditDetailsSchedule data={data} />,
    },
    docs: {
      label: 'Документы',
      route: ROUTES.CABINET.CREDITS.DETAILS.DOCS,
      component: <CreditDetailsDocs data={data} refetch={refetch} />,
    },
  };

  useEffect(() => {
    handleChangeParams({ title, backButton: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, params]);

  if (isLoading || isFetching) {
    return <LoaderPage />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <DetailTabs tabs={tabs} />
    </>
  );
};
