import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQuery } from '../core/utils/rtk.utils';
import { ENDPOINTS } from '../core/constants';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: rtkBaseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.AUTH.LOGIN,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useLoginMutation } = authApi;
