import { InsertDriveFile } from '@mui/icons-material';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { map } from 'lodash';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { BASE_PER_PAGE } from '../../../core/constants';

const VALUES = [10, 20, 30, 40, 50, 100];

export const SelectPerPage: ComponentType<TextFieldProps> = ({
  value = BASE_PER_PAGE,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <TextField
      value={value}
      {...props}
      select
      size="small"
      InputProps={{
        startAdornment: <InsertDriveFile color="primary" fontSize="small" />,
      }}
    >
      {map(VALUES, (value) => (
        <MenuItem key={value} value={value}>
          {t('Показать по') + ' ' + value}
        </MenuItem>
      ))}
    </TextField>
  );
};
