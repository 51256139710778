import { CreditScheduleStatusesEnums } from '../enums';

export const CREDITS_SCHEDULE_STATUSES_LABELS: Record<CreditScheduleStatusesEnums, string> = {
  [CreditScheduleStatusesEnums.NEW]: 'В ожидании оплаты',
  [CreditScheduleStatusesEnums.PAID]: 'Оплачено вовремя',
  [CreditScheduleStatusesEnums.PAID_BEFORE]: 'Оплачено раньше',
  [CreditScheduleStatusesEnums.PAID_LATER]: 'Оплачено позже',
  [CreditScheduleStatusesEnums.PAID_PARTIALLY]: 'Оплачено частично',
  [CreditScheduleStatusesEnums.NOT_PAID]: 'Неоплачено',
};
