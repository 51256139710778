import { ComponentType, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Card, CardContent } from '@mui/material';
import { useLayout } from '../../../../hooks';
import { SCORING_MENU } from '../../../../core/menus';

export const ScoringProductPage: ComponentType = () => {
  const params = useParams();
  const { handleChangeParams } = useLayout();

  const title = 'Sony';

  useEffect(() => {
    handleChangeParams({
      title,
      backButton: true,
      tabs: {
        menu: SCORING_MENU,
        active: 2,
        pathParams: params,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Card>
        <CardContent>Sony</CardContent>
      </Card>
    </>
  );
};
