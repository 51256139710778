import { useState } from 'react';
import { BASE_DRAWER } from '../core/constants';

const localState = localStorage.getItem(BASE_DRAWER);
const initialDrawerState = localState === 'true';

export const useDrawer = () => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(initialDrawerState);

  const handleDrawerToggle = () => {
    setIsCollapsed((prev) => {
      localStorage.setItem(BASE_DRAWER, String(!prev));
      return !prev;
    });
  };

  return { handleDrawerToggle, isCollapsed };
};
