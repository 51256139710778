import { ComponentType } from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Header } from '../../header';
import { Sidebar } from '../../sidebar';
import { authorized } from '../../../hocs/authorized';

export const LayoutCabinet: ComponentType = authorized()(() => {
  return (
    <Box display="flex" flexDirection="row" alignItems="stretch" flex={2}>
      <Sidebar />
      <Box component="main" display="flex" flexDirection="column" flex={2}>
        <Header />
        <Box display="flex" flexDirection="column" flex={2} p={2}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
});
