import { ComponentType, PropsWithChildren } from 'react';
import { Button } from '@mui/material';

export const StatusButtonCustom: ComponentType<PropsWithChildren<any>> = ({
  backgroundColor,
  color,
  children,
}) => {
  return (
    <Button
      variant="contained"
      component="div"
      size="small"
      sx={{ cursor: 'text', height: 'auto', textAlign: 'center', backgroundColor: {backgroundColor}, color: {color} }}
      fullWidth
    >
      {children}
    </Button>
  );
};
