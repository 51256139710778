import { ChangeEvent, ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get, map, filter } from 'lodash';
import { format } from 'date-fns';
import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { BaseDialog, DialogProps } from '../base-dialog';
import { useAddLoanPaymentMutation } from '../../../services/LoansApi';
import { IconClose } from '../../icons';

interface AddPaymentDialogProps extends DialogProps {
  id: string;
  onSuccess?: () => void;
}

interface RowProps {
  amount: string;
  payment_at: string;
}

export const AddPaymentDialog: ComponentType<AddPaymentDialogProps> = ({
  id,
  onSuccess,
  ...props
}) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<RowProps[]>([]);
  const [rowsError, setRowsError] = useState<string[]>([]);
  const [addPayment, { isLoading }] = useAddLoanPaymentMutation();

  let hasZero = !!filter(rows, (row) => row.amount === '0' || row.amount === '').length;
  const today = format(new Date(), 'yyyy-MM-dd');

  const handleFieldChange = (idx: number, field: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const newRows: any = [...rows];
    newRows[idx][field] = e.target.value;
    setRows(newRows);
  };

  const handleAddRow = () => {
    setRows([...rows, { amount: '0', payment_at: today }]);
  };

  const handleRemoveRow = (idx: number) => () => {
    const newRows: any = [...rows];
    newRows.splice(idx, 1);
    setRows(newRows);
  };

  const handleSubmit = () => {
    addPayment({ id, payments: rows })
      .unwrap()
      .then(() => {
        if (props.popDialog) props.popDialog();
        if (onSuccess) onSuccess();
      })
      .catch((e) => {
        const rowsErrors = get(e, 'data.data');
        setRowsError(rowsErrors);
      });
  };

  const hasError = (idx: number) => {
    const rowError: any = get(rowsError, `payments.${idx}.amount`);
    if (rowError) {
      return (
        <Typography variant="caption" color="error" mt={2}>
          {rowError[0]}
        </Typography>
      );
    }
    return null;
  };

  useEffect(() => {
    handleAddRow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseDialog {...props}>
      {map(rows, (row, idx) => (
        <Box key={idx} mb={2}>
          <Box component={Stack} direction="row" spacing={1}>
            <TextField
              onChange={handleFieldChange(idx, 'amount')}
              value={row.amount}
              label={t('Сумма')}
              type="number"
              inputProps={{ min: 0 }}
              sx={{ flex: 2 }}
              disabled={isLoading}
            />
            <TextField
              onChange={handleFieldChange(idx, 'payment_at')}
              value={row.payment_at}
              label={t('Дата')}
              type="date"
              inputProps={{ max: today }}
              sx={{ flex: 2 }}
              disabled={isLoading}
            />
            <Box pt={3}>
              <IconButton onClick={handleRemoveRow(idx)} color="error" disabled={!idx}>
                <IconClose />
              </IconButton>
            </Box>
          </Box>
          {hasError(idx)}
        </Box>
      ))}
      <Box component={Stack} direction="row" justifyContent="flex-end" spacing={1} mt={2}>
        <Button onClick={handleAddRow} disabled={isLoading || hasZero}>
          {t('Добавить строку')}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={isLoading || hasZero}
        >
          {t('Отправить')}
        </Button>
      </Box>
    </BaseDialog>
  );
};
