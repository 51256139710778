import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from '../core/constants';
import { rtkBaseQuery } from '../core/utils/rtk.utils';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: rtkBaseQuery,
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    fetchDashboard: build.query({
      query: (params = {}) => ({
        url: ENDPOINTS.DASHBOARD.FETCH_ALL,
        params,
      }),
    }),
    getLoanDynamics: build.query({
      query: (params = {}) => ({
        url: ENDPOINTS.DASHBOARD.LOAN_DYNAMICS,
        params,
      }),
    }),
    getApplicationConversion: build.query({
      query: (params = {}) => ({
        url: ENDPOINTS.DASHBOARD.APPLICATION_CONVERSION,
        params,
      }),
    }),
  }),
});

export const { useFetchDashboardQuery, useGetLoanDynamicsQuery, useGetApplicationConversionQuery } =
  dashboardApi;
