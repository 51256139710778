import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { APPLICATIONS_ITEMS_COLUMNS } from '../../sources/columns';
import { DataTable } from '../data-table';
import { get } from 'lodash';

interface Props {
  data: any;
}

export const MerchantDetailsApplications: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const loans = get(data, 'data.applications.items', []);

  return <DataTable data={loans} columns={APPLICATIONS_ITEMS_COLUMNS} />;
};
