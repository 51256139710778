import { Outlet, RouteObject, useRoutes } from 'react-router-dom';
import { ErrorPage } from './components/error-page';
import { LayoutBase, LayoutCabinet } from './components/layout';
import { ROUTES } from './core/constants';
import {
  ApplicationsDetailsPage,
  ApplicationsPage,
  CreditsDetailsPage,
  CreditsPage,
  CreditsPaymentsPage,
  DashboardPage,
  HomePage,
  LogoutPage,
  MerchantsDetailsPage,
  MerchantsPage,
  MerchantsNewPage,
  MerchantsShopPage,
  ReportsPage,
  ScoringCustomersPage,
  ScoringDetailsPage,
  ScoringPage,
  ScoringProductPage,
  ScoringShopPage,
  UnderwriterPage,
} from './features/pages';
import UsersPage from './features/pages/users/UsersPage';
import {MerchantBlackList} from "./components/merchant";

const routes: RouteObject[] = [
  {
    path: ROUTES.HOME,
    element: <LayoutBase />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: ROUTES.LOGOUT,
        element: <LogoutPage />,
      },
      { path: '*', element: <ErrorPage errorCode={404} /> },
    ],
  },
  {
    path: ROUTES.CABINET.ROOT,
    element: <LayoutCabinet />,
    children: [
      { index: true, element: <DashboardPage /> },
      {
        path: ROUTES.CABINET.APPLICATIONS.ROOT,
        element: <Outlet />,
        children: [
          { index: true, element: <ApplicationsPage /> },
          {
            path: ROUTES.CABINET.APPLICATIONS.DETAILS.ROOT,
            element: <ApplicationsDetailsPage />,
            children: [
              {
                path: ROUTES.CABINET.APPLICATIONS.DETAILS.TAB,
                element: <ApplicationsDetailsPage />,
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.CABINET.CREDITS.ROOT,
        element: <Outlet />,
        children: [
          { index: true, element: <CreditsPage /> },
          {
            path: ROUTES.CABINET.CREDITS.DETAILS.ROOT,
            element: <CreditsDetailsPage />,
            children: [
              {
                path: ROUTES.CABINET.CREDITS.DETAILS.TAB,
                element: <CreditsDetailsPage />,
              },
            ],
          },
          {
            path: ROUTES.CABINET.CREDITS.DETAILS.PAYMENTS,
            element: <CreditsPaymentsPage />,
          },
        ],
      },
      {
        path: ROUTES.CABINET.MERCHANTS.ROOT,
        element: <Outlet />,
        children: [
          { index: true, element: <MerchantsPage /> },
          {
            path: ROUTES.CABINET.MERCHANTS.DETAILS.ROOT,
            element: <MerchantsDetailsPage />,
            children: [
              {
                path: ROUTES.CABINET.MERCHANTS.DETAILS.TAB,
                element: <MerchantsDetailsPage />,
              },
            ],
          },
          {
            path: ROUTES.CABINET.MERCHANTS.SHOP,
            element: <MerchantsShopPage />,
          },
        ],
      },
      /*{
        path: ROUTES.CABINET.MERCHANTS_NEW.ROOT,
        element: <Outlet />,
        children: [
          { index: true, element: <MerchantsNewPage /> },
          {
            path: ROUTES.CABINET.MERCHANTS_NEW.DETAILS.ROOT,
            element: <MerchantsDetailsPage />,
            children: [
              {
                path: ROUTES.CABINET.MERCHANTS_NEW.DETAILS.TAB,
                element: <MerchantsDetailsPage />,
              },
            ],
          },
          {
            path: ROUTES.CABINET.MERCHANTS_NEW.TAB,
            element: <MerchantBlackList />,
            children: [
              {
                path: ROUTES.CABINET.MERCHANTS_NEW.DETAILS.TAB,
                element: <MerchantsDetailsPage />,
              },
            ],
          }
        ],
      },*/
      {
        path: ROUTES.CABINET.REPORTS,
        element: <ReportsPage />,
      },
      {
        path: ROUTES.CABINET.SCORING.ROOT,
        element: <Outlet />,
        children: [
          { index: true, element: <ScoringPage /> },
          { path: ROUTES.CABINET.SCORING.DETAILS, element: <ScoringDetailsPage /> },
          { path: ROUTES.CABINET.SCORING.SHOP, element: <ScoringShopPage /> },
          { path: ROUTES.CABINET.SCORING.PRODUCT, element: <ScoringProductPage /> },
          { path: ROUTES.CABINET.SCORING.CUSTOMERS, element: <ScoringCustomersPage /> },
        ],
      },
      {
        path: ROUTES.CABINET.UNDERWRITER.ROOT,
        element: <Outlet />,
        children: [
          { index: true, element: <UnderwriterPage /> },
          {
            path: ROUTES.CABINET.UNDERWRITER.DETAILS,
            //element: <UnderwriterDetailsPage />,
            element: <ApplicationsDetailsPage />,
          },
        ],
      },
      {
        path: ROUTES.CABINET.USERS,
        element: <UsersPage />,
      },
    ],
  },
];

export const App = () => useRoutes(routes);
