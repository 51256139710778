import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ru from 'date-fns/locale/ru';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { DialogProvider, LayoutProvider, SnackbarProvider } from './core/providers';
import { ThemeProvider } from './core/theme';
import './i18next';
import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <Suspense fallback="">
        <Provider store={store}>
          <BrowserRouter>
            <HelmetProvider>
              <ThemeProvider>
                <DialogProvider>
                  <SnackbarProvider>
                    <LayoutProvider>
                      <App />
                    </LayoutProvider>
                  </SnackbarProvider>
                </DialogProvider>
              </ThemeProvider>
            </HelmetProvider>
          </BrowserRouter>
        </Provider>
      </Suspense>
    </LocalizationProvider>
  </StrictMode>
);
