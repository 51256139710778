import { Box, IconButton, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { IconDotsHorizontal } from '../../components/icons';
import { StatusButtonCredit } from '../../components/status-button';
import { SumCurrency } from '../../components/sum-currency';
import { ROUTES } from '../../core/constants';
import {
  dateFormat,
  monthlyAndAnnualPercentage,
  separateNumber,
} from '../../core/utils/format.utils';
import { dynamicRoute } from '../../core/utils/routes.utils';
import { IApplication, IColumn, ICredit } from '../../models';

export const CREDITS_COLUMNS: IColumn[] = [
  {
    dataKey: 'id',
    label: 'ID',
    sortable: true,
    renderItem: ({ id }: ICredit) => (
      <Typography
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.CREDITS.DETAILS.ROOT, { id })}
        variant="body2"
        color="primary.main"
      >
        {id}
      </Typography>
    ),
  },
  {
    dataKey: 'application',
    label: 'Заявка',
    sortable: true,
    renderItem: ({ application }: ICredit) => (
      <Typography
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.APPLICATIONS.DETAILS.ROOT, {
          id: get(application, 'id', 0),
        })}
        variant="body2"
        color="primary.main"
      >
        {get(application, 'id', 0)}
      </Typography>
    ),
  },
  {
    dataKey: 'merchant',
    label: 'Мерчант',
    sortable: true,
    renderItem: ({ application }: ICredit) => (
      <Typography
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.MERCHANTS.DETAILS.ROOT, {
          id: get(application, 'merchant.id', 0),
        })}
        variant="body2"
        color="primary.main"
      >
        {get(application, 'merchant.company_name', '-')}
      </Typography>
    ),
  },
  {
    dataKey: 'amount',
    label: 'Сумма кредита (сум)',
    sortable: true,
    renderItem: ({ amount }: ICredit) => (
      <Stack alignItems="flex-end">
        <SumCurrency sum={amount} currency="" />
      </Stack>
    ),
  },
  {
    dataKey: 'loan_amount',
    label: 'Срок',
    sortable: true,
    renderItem: ({ term }: ICredit) => {
      return term ? separateNumber(Number(term)) : 0;
    },
  },
  {
    dataKey: 'percentage_monthly',
    label: '% ставка в мес. (год)',
    sortable: true,
    renderItem: ({ percentage_monthly, percentage_annual }: ICredit) =>
      monthlyAndAnnualPercentage(percentage_monthly, percentage_annual),
  },
  {
    dataKey: 'created_at',
    label: 'Дата',
    sortable: true,
    width: 150,
    renderItem: ({ action_at, finished_at }: IApplication) => (
        <div>
      <Box whiteSpace="nowrap">Выдан: {dateFormat(action_at, 'dd MMM yy')}</Box>
          {(finished_at ? <Box whiteSpace="nowrap">Завершён: {dateFormat(finished_at, 'dd MMM yy')}</Box> : '')}
        </div>
    ),
  },
  {
    dataKey: 'status',
    label: 'Статус',
    sortable: true,
    renderItem: ({ status }: ICredit) => <StatusButtonCredit status={status} />,
  },
  {
    dataKey: '',
    label: '',
    width: 60,
    renderItem: ({ id }: ICredit) => (
      <IconButton
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.CREDITS.DETAILS.ROOT, { id })}
        size="small"
        color="primary"
      >
        <IconDotsHorizontal fontSize="small" />
      </IconButton>
    ),
  },
];
