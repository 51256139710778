import { format } from 'date-fns';
// import { default as NumberFormat } from 'react-number-format';

export function separateNumber(value: number, separator: string = ' '): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export function fullNameInitials(name: string): string {
  if (!name) return '';
  const [lastName, firstName, middleName] = name.split(' ');
  // const lastName = nameArr[0];
  // const firstName = nameArr[1];
  // const middleName = nameArr[2] ? nameArr[2] : '';
  return (
    `${lastName} ${firstName?.length ? firstName[0] + '.' : ''}` +
    (middleName?.length ? ` ${middleName[0]}.` : '')
  );
}

export const monthlyAndAnnualPercentage = (
  monthly: string | number,
  annual: string | number
): string => {
  return `${monthly ? separateNumber(Number(monthly)) : 0}%
  (${annual ? separateNumber(Number(annual)) : 0}%)`;
};

//clear empty object keys
export const clearEmptyObjectKeys = (obj: Record<string, any>) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      delete obj[key];
    }
  });
  return obj;
};

//query string params to object
export const queryStringToObject = (queryString: string): Record<string, any> => {
  if (!queryString) return {};
  const query = queryString.replace('?', '').split('&');
  const queryObj: any = {};
  query.forEach((item) => {
    const [key, value] = item.split('=');
    queryObj[key] = value;
  });
  return queryObj;
};

//number format
export function numberFormat(number: number): string {
  if (!number) return '';

  // return (number).toLocaleString('ru-RU', { minimumFractionDigits: 3 });
  return new Intl.NumberFormat('ru-RU').format(number);
}

// data format
export function dateFormat(date: string, defaultFormat: string = 'dd.MM.yyyy'): string {
  if (!date) return '';
  return format(new Date(date.replace('Z', '')), defaultFormat);
}

export function formatDateToString(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

export function parseCustomISOString(date: string): Date {
  return new Date(date.replace('Z', ''));
}

export function dateToCustomISOString(date: Date | string): string {
  if (!date) return '';
  if (typeof date === 'string') {
    date = new Date(date);
  }
  if (isNaN(date.getTime())) {
    return '';
  }
  return format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'");
}
