import { IMenuItem } from '../../models';
import { ROUTES } from '../constants';

export const SCORING_MENU: IMenuItem[] = [
  {
    label: 'Сведения о компании',
    path: ROUTES.CABINET.SCORING.DETAILS,
  },
  {
    label: 'Информация по магазину',
    path: ROUTES.CABINET.SCORING.SHOP,
  },
  {
    label: 'Информация по товарам',
    path: ROUTES.CABINET.SCORING.PRODUCT,
  },
  {
    label: 'Информация по клиентам ',
    path: ROUTES.CABINET.SCORING.CUSTOMERS,
  },
];
