import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusButtonSuccess } from './StatusButtonSuccess';
import { StatusButtonError } from './StatusButtonError';
import { StatusButtonDisabled } from './StatusButtonDisabled';
import { UnderwriterStatusesEnums } from '../../core/enums';
import { UNDERWRITER_STATUSES_LABELS } from '../../core/constants';

interface StatusButtonUnderwriterProps {
  status: UnderwriterStatusesEnums;
}

export const StatusButtonUnderwriter: ComponentType<StatusButtonUnderwriterProps> = ({
  status,
}) => {
  const { t } = useTranslation();

  switch (status) {
    case UnderwriterStatusesEnums.BAD:
      return (
        <StatusButtonError>
          {t(UNDERWRITER_STATUSES_LABELS[UnderwriterStatusesEnums.BAD])}
        </StatusButtonError>
      );
    case UnderwriterStatusesEnums.GOOD:
      return (
        <StatusButtonSuccess>
          {t(UNDERWRITER_STATUSES_LABELS[UnderwriterStatusesEnums.GOOD])}
        </StatusButtonSuccess>
      );
    default:
      return <StatusButtonDisabled>{status}</StatusButtonDisabled>;
  }
};
