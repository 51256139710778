export const REPORT_TYPES: Record<string, string>[] = [
  {
    label: 'Общий отчет',
    value: 'all',
  },
  {
    label: 'Консолидированный отчет',
    value: 'consolidated',
  },
];
