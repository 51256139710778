import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQuery } from '../core/utils/rtk.utils';

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: rtkBaseQuery,
  tagTypes: ['Reports'],
  endpoints: (build) => ({
    getReports: build.query({
      query: (params) => ({
        url: '/api/reports',
        method: 'GET',
        params,
      }),
      providesTags: ['Reports'],
    }),
    generateReport: build.mutation({
      query: (body) => ({
        url: '/api/reports',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Reports'],
    }),
  }),
});

export const { useGetReportsQuery, useGenerateReportMutation } = reportsApi;
