import { IconButton, Typography } from '@mui/material';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { IconDotsHorizontal } from '../../components/icons';
import { StatusButtonScoring, StatusButtonUnderwriter } from '../../components/status-button';
import { ROUTES } from '../../core/constants';
import { dateFormat, monthlyAndAnnualPercentage } from '../../core/utils/format.utils';
import { dynamicRoute } from '../../core/utils/routes.utils';
import { IApplication, IColumn, IScoring } from '../../models';

export const SCORING_COLUMNS: IColumn[] = [
  {
    dataKey: 'id',
    label: 'ID',
    renderItem: ({ id }: IScoring) => (
      <Typography
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.SCORING.DETAILS, { id })}
        variant="body2"
        color="primary.main"
      >
        {id}
      </Typography>
    ),
  },
  {
    dataKey: 'application',
    label: 'ID заявки',
    renderItem: ({ application }: IScoring) => (
      <Typography
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.APPLICATIONS.DETAILS.ROOT, { id: application.id })}
        variant="body2"
        color="primary.main"
      >
        {application.id}
      </Typography>
    ),
  },
  {
    dataKey: 'application',
    label: 'Мерчант',
    sortable: true,
    renderItem: ({ application }: IScoring) => (
      <Typography
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.MERCHANTS.DETAILS.ROOT, {
          id: get(application, 'merchant.id', 0),
        })}
        variant="body2"
        color="primary.main"
      >
        {get(application, 'merchant.company_name', '-')}
      </Typography>
    ),
  },
  {
    dataKey: 'loan_percentage_monthly',
    label: 'Процентная ставка в месяц (год)',
    sortable: true,
    renderItem: ({ loan_percentage_monthly, loan_percentage_annual }: IScoring) =>
      monthlyAndAnnualPercentage(loan_percentage_monthly, loan_percentage_annual),
  },
  {
    dataKey: 'threshold',
    label: 'Порог скоринга',
    sortable: true,
    renderItem: ({ threshold }: IScoring) => threshold,
  },
  {
    dataKey: 'created_at',
    label: 'Дата',
    sortable: true,
    width: 150,
    renderItem: ({ created_at }: IApplication) => dateFormat(created_at, 'dd.MM.yyyy HH:ii'),
  },
  {
    dataKey: 'status',
    label: 'Статус скоринга',
    sortable: true,
    renderItem: ({ status }: IScoring) => <StatusButtonScoring status={status} />,
  },
  {
    dataKey: 'status_underwriter',
    label: 'Статус андеррайтера',
    sortable: true,
    renderItem: ({ status_underwriter }: IScoring) => (
      <StatusButtonUnderwriter status={status_underwriter} />
    ),
  },
  {
    dataKey: '',
    label: '',
    width: 60,
    renderItem: ({ id }: IScoring) => (
      <IconButton
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.SCORING.DETAILS, { id })}
        size="small"
        color="primary"
      >
        <IconDotsHorizontal fontSize="small" />
      </IconButton>
    ),
  },
];
