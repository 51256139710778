import { TableBody } from '@mui/material';
import { find, get, map } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DOCS_TYPES_LABELS } from '../../core/constants';
import { DocumentTypesEnums, FileTypesEnums } from '../../core/enums';
import { useAttachment } from '../../hooks';
import { StyledTable, StyledTableCell, StyledTableRow } from '../data-table/styled';
import { FileUploader } from '../inputs';

interface Props {
  data: any;
  refetch: () => void;
}

const UPLOADABLE_DOCUMENTS = [
  DocumentTypesEnums.STATEMENT,
  DocumentTypesEnums.INSURANCE,
  DocumentTypesEnums.LOAN_AGREEMENT,
  DocumentTypesEnums.SCHEDULE,
];

export const CreditDetailsDocs: FC<Props> = ({ data, refetch }) => {
  const { t } = useTranslation();
  const { id } = data.data;
  const attachments = get(data, 'data.attachments.items', []);

  const documentsByEntity = (entities: string[]) => {
    return map(entities, (doc) => {
      const attachment = find(attachments, { document_type: doc });
      return {
        docType: doc,
        attachment: !!attachment ? attachment : null,
      };
    });
  };

  const { handleAttach } = useAttachment({ id, refetch });

  return (
    <StyledTable>
      <TableBody>
        {map(documentsByEntity(UPLOADABLE_DOCUMENTS), ({ docType, attachment }, idx) => (
          <StyledTableRow className="no-print" key={idx}>
            <StyledTableCell width={250}>{t(DOCS_TYPES_LABELS[docType])}:</StyledTableCell>
            <StyledTableCell>
              <FileUploader
                onAttach={handleAttach}
                attachment={attachment}
                docType={docType as DocumentTypesEnums}
                fileType={FileTypesEnums.CREDIT}
              />
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};
