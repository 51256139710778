import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { IconButton, Typography } from '@mui/material';
import { StatusButtonApplication } from '../../components/status-button';
import { IconDotsHorizontal } from '../../components/icons';
import { monthlyAndAnnualPercentage, separateNumber } from '../../core/utils/format.utils';
import { dynamicRoute } from '../../core/utils/routes.utils';
import { IApplication, IColumn } from '../../models';
import { ROUTES } from '../../core/constants';
import { SumCurrency } from '../../components/sum-currency';

export const UNDERWRITER_COLUMNS: IColumn[] = [
  {
    dataKey: 'id',
    label: 'ID',
    sortable: true,
    renderItem: ({ id }: IApplication) => (
      <Typography
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.UNDERWRITER.DETAILS, { id })}
        variant="body2"
        color="primary.main"
      >
        {id}
      </Typography>
    ),
  },
  {
    dataKey: 'merchant',
    label: 'Мерчант',
    renderItem: ({ merchant }: IApplication) => (
      <Typography
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.MERCHANTS.DETAILS.ROOT, { id: merchant.id })}
        variant="body2"
        color="primary.main"
      >
        {get(merchant, 'company_name', '')}
      </Typography>
    ),
  },
  {
    dataKey: 'loan_amount_desired',
    label: 'Желаемая сумма (сум)',
    sortable: true,
    renderItem: ({ loan_amount_desired }: IApplication) => (
      <SumCurrency sum={loan_amount_desired} currency="сум" />
    ),
  },
  {
    dataKey: 'loan_term_desired',
    label: 'Желаемый срок',
    sortable: true,
    renderItem: ({ loan_term_desired }: IApplication) => {
      return loan_term_desired ? separateNumber(Number(loan_term_desired)) : 0;
    },
  },
  {
    dataKey: 'loan_amount',
    label: 'Одобренная сумма (сум)',
    sortable: true,
    renderItem: ({ loan_amount }: IApplication) => <SumCurrency sum={loan_amount} currency="сум" />,
  },
  {
    dataKey: 'loan_term',
    label: 'Срок',
    sortable: true,
    renderItem: ({ loan_term }: IApplication) => {
      return loan_term ? separateNumber(Number(loan_term)) : 0;
    },
  },
  {
    dataKey: 'loan_percentage_monthly',
    label: 'Процентная ставка в месяц (год)',
    sortable: true,
    renderItem: ({ loan_percentage_monthly, loan_percentage_annual }: IApplication) =>
      monthlyAndAnnualPercentage(loan_percentage_monthly, loan_percentage_annual),
  },
  {
    dataKey: 'loan_security',
    label: 'Обеспечение',
    sortable: true,
    renderItem: ({ loan_security }: IApplication) => loan_security,
  },
  {
    dataKey: 'status',
    label: 'Статус',
    sortable: true,
    renderItem: ({ status }: IApplication) => <StatusButtonApplication status={status} />,
  },
  {
    dataKey: '',
    label: '',
    width: 60,
    renderItem: ({ id }: IApplication) => (
      <IconButton
        component={Link}
        to={dynamicRoute(ROUTES.CABINET.UNDERWRITER.DETAILS, { id })}
        size="small"
        color="primary"
      >
        <IconDotsHorizontal fontSize="small" />
      </IconButton>
    ),
  },
];
