import { get } from 'lodash';
import { useInfoQuery } from '../services/MeApi';
import { RoleEnums } from '../core/enums';

export const useManagers = () => {
  const { data } = useInfoQuery({});

  const userRole = get(data, 'data.role_code');

  const isAdmin = userRole === RoleEnums.ADMIN;

  const isManager = userRole === RoleEnums.ADMIN || userRole === RoleEnums.UNDERWRITER;

  const isSarvarShermatov = get(data, 'data.email') === 'sarvar.shermatov@msb.uz';

  const isSanjarXalilov = get(data, 'data.email') === 'sanjar.xalilov@msb.uz';

  return { isAdmin, isManager, isSarvarShermatov, isSanjarXalilov };
};
