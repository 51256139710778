import { Avatar, Box, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import { ComponentType } from 'react';
import { fullNameInitials } from '../../../core/utils/format.utils';
import { useInfoQuery } from '../../../services/MeApi';
import { IconUser } from '../../icons';

export const UserCard: ComponentType = () => {
  const { data } = useInfoQuery({});

  const role = get(data, 'data.role_code', 'гость');
  const firstName = get(data, 'data.first_name', '');
  const lastName = get(data, 'data.last_name', '');
  const secondName = get(data, 'data.second_name', '');
  const fullName = fullNameInitials([lastName, firstName, secondName].join(' '));

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Avatar>
        <IconUser />
      </Avatar>
      <Box>
        {fullName && (
          <Typography variant="body2" fontWeight={500} lineHeight={1.1}>
            {fullName}
          </Typography>
        )}
        {role && (
          <Typography variant="caption" color="text.secondary" lineHeight={1.1}>
            {role}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
