import { Box, Card, CardContent, Pagination, Stack } from '@mui/material';
import { ComponentType, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { DataTable } from '../../../components/data-table';
import DataTableFilter from '../../../components/data-table-filter/DataTableFilter';
import { SelectPerPage } from '../../../components/inputs';
import { LoaderPage } from '../../../components/loader';
import { useFilter, useLayout, useResources } from '../../../hooks';
import { useFetchAllMerchantsQuery } from '../../../services/MerchantsApi';
import { MERCHANTS_COLUMNS } from '../../../sources/columns';

export const MerchantsPage: ComponentType = () => {
  const { t } = useTranslation();
  const { handleChangeParams } = useLayout();
  const { getFieldValue, handleSubmit } = useFilter();
  const {
    dataList,
    currentPage,
    perPage,
    pagesCount,
    orderBy,
    orderDirection,
    isFetching,
    isLoading,
    handleChangeLimit,
    handleChangePage,
    handleChangeSort,
  } = useResources(useFetchAllMerchantsQuery);

  const title = t('Мерчанты');

  useEffect(() => {
    handleChangeParams({ title });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <LoaderPage />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <DataTableFilter noStatus type="merchants" />

      <Box component={Card} display="flex" flexDirection="column" flex={2}>
        <Box component={CardContent} flex={2}>
          <DataTable
            data={dataList}
            isLoading={isFetching}
            columns={MERCHANTS_COLUMNS}
            orderBy={orderBy}
            orderDirection={orderDirection}
            onOrder={handleChangeSort}
          />
        </Box>
        <Box
          component={Stack}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={1.5}
          p={1.5}
          borderTop="1px solid #E5E9EB"
        >
          <SelectPerPage onChange={handleChangeLimit} value={perPage} />
          <Pagination onChange={handleChangePage} page={currentPage} count={pagesCount} />
        </Box>
      </Box>
    </>
  );
};
