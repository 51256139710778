import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { map } from 'lodash';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APPLICATIONS_STATUSES_LABELS } from '../../../core/constants';
import { ApplicationStatusesEnums } from '../../../core/enums';

type Props = TextFieldProps & { prompt?: boolean };

export const SelectApplicationStatus: ComponentType<Props> = ({ prompt, ...props }) => {
  const { t } = useTranslation();
  const [statuses, setStatuses] = useState<string[]>([]);

  return (
    <TextField
      select
      {...props}
      value={statuses.join(',')}
      SelectProps={{
        multiple: true,
        value: statuses,
        onChange: (e: any) => setStatuses(e.target.value),
      }}
    >
      {prompt && <MenuItem value={''}>{t('Все')}</MenuItem>}
      {map(ApplicationStatusesEnums, (status, key) => (
        <MenuItem value={status} key={key}>
          {t(APPLICATIONS_STATUSES_LABELS[status])}
        </MenuItem>
      ))}
    </TextField>
  );
};
