import { Stack, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { get, isEmpty } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { numberFormat } from '../../core/utils/format.utils';
import { useFetchOneApplicationQuery } from '../../services/ApplicationsApi';
import { StyledTable, StyledTableCell, StyledTableRow } from '../data-table/styled';

interface Props {}

export const ApplicationDetailsScoring: FC<Props> = () => {
  const { t } = useTranslation();
  const params = useParams();
  const id = get(params, 'id', null);
  const { data } = useFetchOneApplicationQuery(id);

  const scoring = get(data, 'data.scoring_result', []);
  const score = get(scoring, 'score');

  if (isEmpty(score)) {
    return null;
  }

  return (
    <Stack gap={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
        <Typography variant="h5">{t('Уровень кредитного риска')}</Typography>
        <Stack direction="row" alignItems="center" spacing={4}>
          <Typography variant="body1" fontWeight={600}>
            {get(score, 'score_results.code')}
          </Typography>
          <Typography variant="body1" fontWeight={600}>
            {get(score, 'score_results.text')}
          </Typography>
        </Stack>
      </Stack>
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableCell>{t('Финансовые риски')}</StyledTableCell>
            <StyledTableCell>{t('Взвешенный риск')}</StyledTableCell>
            <StyledTableCell>{t('Показатель')}</StyledTableCell>
            <StyledTableCell>{t('Score')}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>{t('Сумма кредита к выручке')}</StyledTableCell>
            <StyledTableCell>60%</StyledTableCell>
            <StyledTableCell>
              {numberFormat(get(score, 'loan_amount_to_revenue_index'))}
            </StyledTableCell>
            <StyledTableCell>
              {numberFormat(get(score, 'loan_amount_to_revenue_score'))}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>{t('Срок функционирования бизнеса')}</StyledTableCell>
            <StyledTableCell>20%</StyledTableCell>
            <StyledTableCell>{numberFormat(get(score, 'term_of_business_index'))}</StyledTableCell>
            <StyledTableCell>{numberFormat(get(score, 'term_of_business_score'))}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>{t('ТМЗ к сумме кредита')}</StyledTableCell>
            <StyledTableCell>20%</StyledTableCell>
            <StyledTableCell>
              {numberFormat(get(score, 'tmz_to_loan_amount_index'))}
            </StyledTableCell>
            <StyledTableCell>
              {numberFormat(get(score, 'tmz_to_loan_amount_score'))}
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </StyledTable>
    </Stack>
  );
};
