import { format } from 'date-fns';
import { SumCurrency } from '../../components/sum-currency';
import { StatusButtonCreditSchedule } from '../../components/status-button';
import { IRow } from '../../models';

export const CREDITS_SCHEDULE_ROWS: IRow[] = [
  {
    label: 'Остаток по кредиту',
    field: ['loan_balance'],
    renderItem: (value: any) => <SumCurrency sum={value} currency="сум" />,
  },
  {
    label: 'Основной долг',
    field: ['payment_loan'],
    renderItem: (value: number | string) => <SumCurrency sum={value} currency="сум" />,
  },
  {
    label: 'Проценты',
    field: ['payment_percentage'],
    renderItem: (value: number | string) => <SumCurrency sum={value} currency="сум" />,
  },
  {
    label: 'Сумма платежа',
    field: ['payment_amount'],
    renderItem: (value: number | string) => <SumCurrency sum={value} currency="сум" />,
  },
  {
    label: 'Дата платежа',
    field: ['payment_at'],
    renderItem: (value: any) => (value ? format(new Date(value), 'dd.MM.yyyy') : ''),
  },
  {
    label: 'Статус',
    field: ['status'],
    renderItem: (value: any) => <StatusButtonCreditSchedule status={value} />,
  },
];
