import { getIn } from 'formik';

export const formikTextFieldProps = (formik: any, field: string) => {
  const errorText = getIn(formik, `errors.${field}`, '');
  const isTouched = getIn(formik, `touched.${field}`, false);
  const hasErrors = errorText && isTouched;
  return {
    ...formik.getFieldProps(field),
    error: hasErrors,
    helperText: errorText,
  };
};
